import React from 'react';
import classNames from 'classnames/bind';
import styles from './Detail.module.scss'; // SCSS 파일
import useDetail from './hooks/useDetail';
import dayjs from 'dayjs';

const cn = classNames.bind(styles);

const Detail = () => {
  const { item, handleGoToBack } = useDetail();

  if (!item) {
    return <></>;
  }
  return (
    <div className={cn('container')}>
      <div className={cn('header')}>
        <div className={cn('badge')}>{item.boardCategoryName}</div>
        <div className={cn('title-content')}>
          <h1 className={cn('title')}>{item.postTitle}</h1>
          <p className={cn('date')}>
            {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}
          </p>
        </div>
      </div>

      <div className={cn('divider')} />

      {/* content 내에 이미지가 포함되어 있음 */}
      <div
        className={`${cn('content')} editor-styles`}
        dangerouslySetInnerHTML={{ __html: item.postContents }}
      />

      <div className={cn('button-wrapper')}>
        <button className={cn('button')} onClick={handleGoToBack}>
          목록
        </button>
      </div>
    </div>
  );
};

export default Detail;
