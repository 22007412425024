import React, { useCallback, useEffect, useState } from 'react';
import styles from './Preview.module.scss';
import useHtmlForPdf from '../../../hooks/useHtmlForPdf';
import classNames from 'classnames/bind';
import recordQuestionAnalyze from '../../../api/record-question-analyze';
import {
  GeneratedQuestionsResponse,
  GetGeneratedQuestionsResponse,
  RecordQuestionsGeneratedRequest,
} from '../../../types/record-question-analyze/api';
import { getCalculateGrade } from '../../../utils';
import { useNavigate } from 'react-router-dom';

const cn = classNames.bind(styles);

const Preview = () => {
  const navigate = useNavigate();
  // items를 상태로 설정
  const [items, setItems] = useState<GetGeneratedQuestionsResponse[]>([]);
  const [
    appliedRecordCollegeAdmissionAnalyze,
    setAppliedRecordCollegeAdmissionAnalyze,
  ] = useState<
    GeneratedQuestionsResponse['appliedRecordCollegeAdmissionAnalyze'] | null
  >(null);

  const { handleDownloadPdf, printRef } = useHtmlForPdf();

  const getRecodeQuestionAnalyze = useCallback(
    async (generatedParam: RecordQuestionsGeneratedRequest) => {
      const response =
        await recordQuestionAnalyze.getRecodeQuestionAnalyze(generatedParam);

      if (response.code === '2000' && response.data) {
        const filter = response.data.generatedQuestions.filter(
          item => item.questionContents,
        );

        setItems(filter);
        setAppliedRecordCollegeAdmissionAnalyze(
          response.data.appliedRecordCollegeAdmissionAnalyze,
        );
      }
    },
    [],
  );
  const handleGoToBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = parseInt(queryParams.get('id') || '', 10);
    const idx = parseInt(queryParams.get('idx') || '', 10);

    getRecodeQuestionAnalyze({
      studentRecordIdx: id,
      studentRecordAnalyzeIdx: idx,
    }).then();
  }, [location]);

  return (
    <div>
      <div className={cn('container')} ref={printRef}>
        <h1 className={cn('title')}>수시탐탐 면접 예상질문</h1>

        {!!appliedRecordCollegeAdmissionAnalyze && (
          <div className={cn('school-info-container')}>
            <div className={cn('school-info')}>
              {
                appliedRecordCollegeAdmissionAnalyze.studentRecordAnalyzeStatus
                  .eduSchoolNmKr
              }{' '}
              |{' '}
              {
                appliedRecordCollegeAdmissionAnalyze.studentRecordAnalyzeStatus
                  .studentName
              }{' '}
              |{' '}
              {getCalculateGrade(
                appliedRecordCollegeAdmissionAnalyze.studentRecordAnalyzeStatus
                  .studentEntryYear,
              )}{' '}
            </div>
            <div className={cn('school-details')}>
              {
                appliedRecordCollegeAdmissionAnalyze.appliedCollegeAdmission
                  .universityName
              }{' '}
              |{' '}
              {
                appliedRecordCollegeAdmissionAnalyze.appliedCollegeAdmission
                  .collegeDepartmentName
              }{' '}
              |{' '}
              {
                appliedRecordCollegeAdmissionAnalyze.appliedCollegeAdmission
                  .collegeAdmissionName
              }{' '}
            </div>
          </div>
        )}

        <div className={cn('question-list')}>
          {items.map(({ questionContents, answerContents, idx }) => {
            return (
              <div className={cn('question-card')} key={idx}>
                <h3 className={cn('question-title')}>{questionContents}</h3>
                <p
                  className={cn('question-content', {
                    'empty-answer': !answerContents,
                  })}
                >
                  {answerContents}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      {/* 하단에 다운로드 및 목록보기 버튼 */}
      <div className={cn('button-container')}>
        <button
          className={cn('button', { secondary: true })}
          onClick={handleGoToBack}
        >
          취소
        </button>
        <button
          className={cn('button', { primary: true })}
          onClick={() =>
            handleDownloadPdf(
              appliedRecordCollegeAdmissionAnalyze?.studentRecordAnalyzeStatus
                .studentName,
            )
          }
        >
          PDF 다운로드
        </button>
      </div>
    </div>
  );
};

export default Preview;
