import React, { useEffect } from 'react';

export interface PopupProps {
  width?: string;
  height?: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  footer?: React.ReactNode; // 버튼 영역을 위한 추가 prop
  backgroundClickClose?: boolean;
  isCloseButton?: boolean;
  maxHeight?: string;
  customMargin?: string;
}

const Popup = ({
  width = 'max-w-lg',
  height = 'h-auto',
  isOpen,
  onClose,
  children,
  footer,
  backgroundClickClose = true,
  isCloseButton = true,
  maxHeight,
  customMargin,
}: PopupProps) => {
  // 스크롤 제어를 위해 useEffect 사용
  useEffect(() => {
    if (isOpen) {
      // 팝업이 열리면 body의 스크롤을 멈춤
      document.body.style.overflow = 'hidden';
    } else {
      // 팝업이 닫히면 body의 스크롤을 활성화
      document.body.style.overflow = 'auto';
    }

    // 컴포넌트가 언마운트되거나 팝업이 닫힐 때 스크롤을 다시 활성화
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={() => {
        if (backgroundClickClose) {
          onClose();
        }
      }}
    >
      <div
        className={`relative bg-white rounded-lg p-4 shadow-lg mx-4 ${width} ${height}`}
        onClick={e => e.stopPropagation()}
        style={maxHeight ? { maxHeight } : {}} // 팝업 최대 높이를 설정
      >
        {isCloseButton && (
          <button
            onClick={onClose}
            className="absolute top-2 right-2 p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}

        <div
          className={
            customMargin !== undefined ? customMargin : 'mb-4 mt-[20px]'
          }
        >
          {children}
        </div>
        {footer && (
          <div className="mt-4 flex justify-center space-x-2">{footer}</div>
        )}
      </div>
    </div>
  );
};

export default Popup;
