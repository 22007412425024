import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useNoData = () => {
  const navigate = useNavigate();

  const handleGoToInterviewQuestion = useCallback(() => {
    navigate("/interview/question");
  }, [navigate]);

  const handleGoToLifeRecodeWrite = useCallback(() => {
    navigate("/liferecode/write");
  }, [navigate]);

  return {
    handleGoToInterviewQuestion,
    handleGoToLifeRecodeWrite,
  };
};

export default useNoData;
