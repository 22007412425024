import { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const useHtmlForPdf = () => {
  const printRef = useRef<HTMLDivElement>(null);

  const handleDownloadPdf = async (fileName?: string) => {
    const element = printRef.current;

    if (!element) return;

    try {
      // HTML을 캡처하여 캔버스로 변환
      // 전체 HTML을 캔버스로 변환
      const canvas = await html2canvas(element, { scale: 2 }); // 스케일을 더 높게 잡아 더 나은 해상도 얻기
      const imageData = canvas.toDataURL('image/png');

      // jsPDF 인스턴스 생성 (A4 크기, 세로)
      const pdf = new jsPDF('p', 'mm', 'a4');

      // PDF 페이지 크기 설정 (A4: 210mm x 297mm)
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // 이미지의 크기를 기준으로 비율 계산 (A4 용지 비율에 맞추어 조정)
      // const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;

      let heightLeft = imgHeight; // 처음 남은 이미지 높이 설정
      let position = 0; // 페이지 내 이미지 시작 위치

      // 첫 페이지에 이미지 추가
      pdf.addImage(imageData, 'PNG', 0, position, pdfWidth, imgHeight);
      heightLeft -= pdfHeight; // 남은 이미지 높이에서 한 페이지 높이만큼 빼기

      // 남은 이미지 높이를 기준으로 페이지를 추가
      while (heightLeft > 0) {
        position = heightLeft - imgHeight; // 다음 이미지 위치를 설정
        pdf.addPage();
        pdf.addImage(imageData, 'PNG', 0, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight; // 다음 페이지에서도 남은 높이를 빼주기
      }

      // PDF 파일 저장
      pdf.save(`${fileName}면접질문.pdf` || 'download.pdf');
    } catch (error) {
      console.error('PDF 생성 중 오류가 발생했습니다:', error);
    }
  };

  return {
    printRef,
    handleDownloadPdf,
  };
};

export default useHtmlForPdf;
