import { Response } from '../../types';
import { KakaoTokenReponse, KakaoTokenRequest } from '../../types/kakao/api';
import { KakaoToken } from '../../types/kakao/module';
import { kakaoPost } from '../../utils';
import axios from 'axios';

export class KakaoApi {
  async authToken(code: string) {
    const response = await kakaoPost<
      Response<KakaoTokenReponse>,
      KakaoTokenRequest
    >(
      '/oauth/token',
      {
        grant_type: 'authorization_code',
        client_id: process.env.REACT_APP_KAKAO_INIT || '',
        redirect_uri: process.env.REACT_APP_KAKAO_REDIREACT_URL || '',
        code,
      },
      'application/x-www-form-urlencoded;charset=utf-8',
    );

    if (response.status === 200) {
      return {
        ...response,
        data: {
          accessToken: response.data?.access_token || '',
        },
      } as Response<KakaoToken>;
    } else {
      return {
        status: response.status,
        data: undefined,
      };
    }
  }

  async channelsYn(token: string) {
    const response = await axios.get<{ channels: { relation: string }[] }>(
      'https://kapi.kakao.com/v2/api/talk/channels',
      {
        headers: {
          Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 추가
        },
      },
    );

    return response.data.channels[0].relation;
  }
}

// KakaoApi 클래스의 인스턴스를 생성하고 export합니다.
const kakaoApi = new KakaoApi();
export default kakaoApi;
