import React, { useCallback, useEffect, useState } from 'react';
import styles from './Result.module.scss';
import CardList from './CardList/CardList';
import NoData from './NoData';
import DetailList from './Detail/DetailList';

import recordQuestionAnalyze from '../../../api/record-question-analyze';
import {
  GetCollegeAdmissionApplicationResponse,
  GetRecordQuestionAnalyzeResponse,
  RecordQuestionsGeneratedRequest,
} from '../../../types/record-question-analyze/api';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const Result = () => {
  const [items, setItems] = useState<
    {
      studentRecordAnalyzeStatus: GetRecordQuestionAnalyzeResponse;
      appliedCollegeAdmission: GetCollegeAdmissionApplicationResponse;
    }[]
  >([]);

  const [result, setResult] = useState(false);
  const [isApi, setIsApi] = useState(false);
  const [generatedParam, setGeneratedParam] =
    useState<RecordQuestionsGeneratedRequest>({
      studentRecordAnalyzeIdx: 0,
      studentRecordIdx: 0,
    });
  const getRecodeQuestionAnalyze = useCallback(async () => {
    const response = await recordQuestionAnalyze.getRecodeQuestionAnalyzeList();

    if (response.code === '2000') {
      if (response.data) {
        setItems(response.data);
      }
    }

    setIsApi(true);
  }, []);

  useEffect(() => {
    getRecodeQuestionAnalyze().then();
  }, []);

  if (!isApi) {
    return <></>;
  }

  return (
    <div className={cn('container')}>
      <h1 className={cn('title')}>접수 결과 확인</h1>

      {/* 데이터가 없으면 노데이터 처리 */}
      {items.length <= 0 && <NoData />}

      {items.length > 0 && !result && (
        <CardList
          items={items}
          setResult={setResult}
          setGeneratedParam={setGeneratedParam}
        />
      )}

      {items.length > 0 && result && (
        <DetailList setResult={setResult} generatedParam={generatedParam} />
      )}
    </div>
  );
};

export default Result;
