import React from "react";
import { Main } from "../../feat-components";
import { Helmet } from "react-helmet-async";

const Page = () => {
  return (
    <>
      <Helmet />
      <Main />
    </>
  );
};

export default Page;
