import { get, post } from '../../utils';
import { Response } from '../../types';
import {
  CollegesAdmissionsListRequest,
  CollegesAdmissionsListResponse,
  CreateCollegesAdmissionsApplyRequest,
  CreateCollegesAdmissionsRequest,
  CreateCollegesAdmissionsResponse,
  GetCreateCollegeAdmissionApplyResponse,
} from '../../types/predict-questions/api';

export class QuestionsApi {
  getCollegesAdmissionsList(studentRecodeIdx: CollegesAdmissionsListRequest) {
    return get<Response<CollegesAdmissionsListResponse[]>>(
      '/api/predict-questions/collegesAdmissions',
      studentRecodeIdx,
    );
  }

  createCollegesAdmissions(body: CreateCollegesAdmissionsRequest[]) {
    return post<
      Response<CreateCollegesAdmissionsResponse>,
      CreateCollegesAdmissionsRequest[]
    >('/api/predict-questions/collegesAdmissions', body);
  }

  updateCollegesApply(data: CreateCollegesAdmissionsApplyRequest) {
    return post<
      Response<GetCreateCollegeAdmissionApplyResponse>,
      CreateCollegesAdmissionsApplyRequest
    >('/api/predict-questions/apply', data);
  }
}

// QuestionsApi 클래스의 인스턴스를 생성하고 export합니다.
const questionsApi = new QuestionsApi();
export default questionsApi;
