import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  AuthProvider,
  ConfirmProvider,
  ToastProvider,
  AlertProvider,
} from './contexts';
import './assets/scss/foundations.scss'; // SCSS 파일을 직접 임포트
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Footer, Header, ScrollToTop } from './components';
import {
  Instructions,
  InterviewQuestion,
  InterviewPreview,
  InterviewResult,
  LifeRecodeWrite,
  Main,
  MemberInfo,
  MemberSecession,
  NotFound,
  NoticeDetail,
  NoticeList,
  Oauth,
  MemberLogin,
  LifeRecodeUpload,
} from './pages';
import { PrivateRoute } from './feat-components';

const App = () => {
  return (
    <HelmetProvider>
      <Helmet>
        {process.env.NODE_ENV === 'development' && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>

      <Router>
        <AuthProvider>
          <ConfirmProvider>
            <AlertProvider>
              <ToastProvider>
                <ScrollToTop />
                <Header />
                <div id="body">
                  <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/instructions" element={<Instructions />} />
                    <Route path="/oauth" element={<Oauth />} />

                    <Route path="member" element={<PrivateRoute />}>
                      <Route path="info" element={<MemberInfo />} />
                      <Route path="secession" element={<MemberSecession />} />

                      <Route path="*" element={<NotFound />} />
                    </Route>

                    <Route path="member/login" element={<MemberLogin />} />

                    <Route path="notice/*" element={<PrivateRoute />}>
                      <Route path="list" element={<NoticeList />} />
                      <Route path="detail" element={<NoticeDetail />} />

                      <Route path="*" element={<NotFound />} />
                    </Route>

                    <Route path="interview/*" element={<PrivateRoute />}>
                      <Route path="result" element={<InterviewResult />} />
                      <Route path="preview" element={<InterviewPreview />} />
                      <Route path="question" element={<InterviewQuestion />} />

                      <Route path="*" element={<NotFound />} />
                    </Route>

                    <Route path="liferecode/*" element={<PrivateRoute />}>
                      <Route path="write" element={<LifeRecodeWrite />} />
                      <Route path="upload" element={<LifeRecodeUpload />} />

                      <Route path="*" element={<NotFound />} />
                    </Route>

                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </div>
                <Footer />
              </ToastProvider>
            </AlertProvider>
          </ConfirmProvider>
        </AuthProvider>
      </Router>
    </HelmetProvider>
  );
};

export default App;
