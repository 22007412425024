import React from 'react';
import { Card, Stack, TabMenu } from '../../components';
import useInstructions from './hooks/useInstructions';
import { getUrlPath } from '../../utils';
import styles from './Instructions.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const Instructions = () => {
  const {
    type,
    handleTabClick,
    tabs,
    handleGoToLifeRecodeWrite,
    handleOpenLink,
  } = useInstructions();

  return (
    <div
      className={`flex items-center justify-center min-h-screen bg-white ${cn(
        'instructions-container',
      )}`}
    >
      <Stack direction="vertical" className={cn('gap-container')}>
        <h1 className={cn('text-center')}>
          <div className={cn('inline-text')}>
            <span className="text-primary-300 text-3xl md:text-5xl font-bold md:leading-[60px] leading-[40px]">
              분석할 생기부
            </span>
            <span className="text-gray-900 text-3xl md:text-5xl font-bold md:leading-[60px] leading-[40px]">
              를
            </span>
          </div>
          <span className="text-gray-900 text-3xl md:text-5xl font-bold md:leading-[60px] leading-[50px]">
            준비해주세요.
          </span>
        </h1>

        <div className={cn('image-container')}>
          <TabMenu
            tabs={tabs}
            handleTabClick={index => handleTabClick(index)}
          />
          <img
            src={getUrlPath(`instructions/${type}.png`)}
            alt="정부24 안내"
            className={cn('tab-image')}
          />
          <img
            src={getUrlPath(`instructions/${type}_mo.png`)}
            alt={`${type}_mo`}
            className={cn('tab-image-mo')}
          />

          <div className={cn('card-container')}>
            <Card
              title={
                type === 'government24'
                  ? '정부24 바로가기'
                  : '나이스플러스 바로가기'
              }
              subtitle="생기부 준비하러 이동할까요?"
              onClick={handleOpenLink}
            />
            <Card
              title="생기부 등록 바로가기"
              subtitle="생기부가 준비되셨나요?"
              onClick={handleGoToLifeRecodeWrite}
            />
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default Instructions;
