import React, { useCallback } from 'react';
import styles from './Init.module.scss';
import { getUrlPath } from '../../../../utils';
import { Card } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

export interface InitProps {
  isLifeRecode: boolean;
  isInterviewRequest: boolean;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const cn = classNames.bind(styles);

const Init = ({ isLifeRecode, setStep, isInterviewRequest }: InitProps) => {
  const navigate = useNavigate();

  const handleGoToLifeRecodeWrite = useCallback(() => {
    navigate('/liferecode/write');
  }, [navigate]);

  const handleCheckInterviewRequest = useCallback(() => {
    if (isInterviewRequest) {
      navigate('/interview/result');
    } else {
      setStep(1);
    }
  }, [isInterviewRequest]);

  return (
    <div className={cn('container')}>
      <div className={cn('imageContainer')}>
        <img
          className={cn('desktopImage')}
          src={getUrlPath('interview/interview_question.png')}
          alt="interview_question"
        />
        <img
          className={cn('mobileImage')}
          src={getUrlPath('interview/interview_question_mo.png')}
          alt="interview_question_mo"
        />
      </div>

      {isLifeRecode ? (
        <div className={cn('button-group')}>
          <button
            className={cn('submit-button')}
            onClick={handleCheckInterviewRequest}
          >
            {isInterviewRequest
              ? '접수 결과 확인하러 가기'
              : '면접 예상 질문 접수하기'}
          </button>
        </div>
      ) : (
        <div className={cn('action-buttons')}>
          <Card
            title="생기부 등록 바로가기"
            subtitle="생기부 준비하러 이동할까요?"
            onClick={handleGoToLifeRecodeWrite}
          />
        </div>
      )}
    </div>
  );
};

export default Init;
