import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { getCookie, setCookie } from '../../../../utils/cookie';
import { COOKIE } from '../../../../constants';

export interface UsePopup {
  popupType: string;
}
const usePopup = ({ popupType }: UsePopup) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  const handleCloseClick = useCallback((isDay: boolean) => {
    if (isDay) {
      const todayEnd = dayjs().endOf('day').toDate(); // 오늘의 마지막 시간 (23:59:59)
      setCookie(`${COOKIE.init}${popupType}`, 'true', {
        path: '/',
        expires: todayEnd,
      });
    }
    setPopupOpen(false);
  }, []);

  const handleImageLoad = () => {
    setIsImageLoaded(true); // 이미지가 로드되면 상태 업데이트

    // 팝업 오픈
    if (!!getCookie(`${COOKIE.init}${popupType}`)) {
      setPopupOpen(false);
    } else {
      setPopupOpen(true);
    }
  };

  return {
    popupOpen,
    isImageLoaded,
    handleCloseClick,
    handleImageLoad,
    setPopupOpen,
  };
};

export default usePopup;
