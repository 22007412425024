import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authApi from '../../../../api/auth';
import { useAlert } from '../../../../contexts';
import dayjs from 'dayjs';

const useInfo = () => {
  const [isCallApi, setIsCallApi] = useState<boolean>(false); // api 호출 여부
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(true); // 마케팅동의를 했는지 여부
  const [notificationDate, setNotificationDate] = useState<string>(''); // 마케팅 날짜 여부
  const [name, setName] = useState(''); // 이름 상태
  const [phone, setPhone] = useState(''); // 휴대폰 번호 상태
  const [email, setEmail] = useState(''); // 이메일 상태
  const [isPrivacyConsentOpen, setPrivacyConsentOpen] = useState(false);

  const navigate = useNavigate();
  const { alert } = useAlert();

  const handleGoToSecession = useCallback(() => {
    navigate('/member/secession');
  }, [navigate]);

  const handleToggle = useCallback(async () => {
    setIsNotificationEnabled(!isNotificationEnabled);
    const response = await authApi.notify();

    if (response.code === '2000' && response.data) {
      const { notifyUpdatedAt, isNotify } = response.data;
      setIsNotificationEnabled(isNotify);
      setNotificationDate(dayjs(notifyUpdatedAt).format('YYYY.MM.DD'));
    }
  }, [isNotificationEnabled]);

  const handlePrivacyConsentClose = () =>
    setPrivacyConsentOpen(!isPrivacyConsentOpen);
  const handleCallInfoApi = useCallback(async () => {
    const response = await authApi.info();

    if (response.code === '2000' && response.data) {
      setIsCallApi(true);

      const { infoName, infoPhone, infoEmail, notifyUpdatedAt, isNotify } =
        response.data;
      setName(infoName);
      setPhone(infoPhone);
      setEmail(infoEmail);
      setIsNotificationEnabled(isNotify);
      setNotificationDate(dayjs(notifyUpdatedAt).format('YYYY.MM.DD'));
    } else {
      alert({
        title: response.message,
      });
    }
  }, []);

  useEffect(() => {
    handleCallInfoApi().then();
  }, []);

  return {
    handleGoToSecession,
    handleToggle,
    isNotificationEnabled,
    name,
    phone,
    email,
    isPrivacyConsentOpen,
    isCallApi,
    notificationDate,
    handlePrivacyConsentClose,
  };
};

export default useInfo;
