import { filter, get } from 'lodash-es';
import dayjs from 'dayjs';

export * from './axios';
export * from './kakaoAxios';

/**
 * 키의 값이 없는 요소를 필터링하는 함수
 * @returns
 * @param array
 * @param key
 */
export const findMissingValue = <T>(array: T[], key: string) => {
  return filter(array, item => !get(item, key));
};

/**
 * 이미지 파일을 가져오는 방법
 * @returns
 * @param link
 */
export const getUrlPath = (link: string) => {
  return `${process.env.REACT_APP_AWS_IMAGE_PATH}edu/${link}`;
};

export const getCalculateGrade = (entryYear: number, isNumber?: boolean) => {
  const currentYear = dayjs().year();
  const yearDifference = currentYear - entryYear;

  if (yearDifference >= 3) {
    return isNumber ? 0 : '졸업생';
  }

  switch (yearDifference) {
    case 2:
      return isNumber ? 3 : '3학년';
    case 1:
      return isNumber ? 2 : '2학년';
    case 0:
      return isNumber ? 1 : '1학년';
    default:
      return null; // 필요에 따라 기본 값을 설정합니다.
  }
};
