import React from "react";
import classNames from "classnames/bind";
import { getUrlPath } from "../../../utils";
import styles from "./Login.module.scss";
import useKakaoLogin from "../../../hooks/useKakaoLogin";

const cn = classNames.bind(styles);

const Login = () => {
  const { login } = useKakaoLogin();

  return (
    <div className={cn("login-page")}>
      <div className={cn("container")}>
        <h1 className={cn("title")}>로그인</h1>

        {/* 퍼즐 이미지와 텍스트가 포함된 하나의 이미지 */}
        <div className={cn("image-wrapper")}>
          <img
            src={getUrlPath("login/login_image.png")} // 텍스트와 퍼즐 이미지가 하나로 합쳐진 이미지 파일
            alt="login_image"
            className={cn("puzzle-image")}
          />
          <img
            src={getUrlPath("login/login_image_mo.png")} // 텍스트와 퍼즐 이미지가 하나로 합쳐진 이미지 파일
            alt="login_image_mo"
            className={cn("puzzle-image-mo")}
          />
        </div>

        {/* 카카오 로그인 버튼 */}
        <button className={cn("kakao-button")}>
          <img
            src={getUrlPath("login/kakao_login.png")}
            alt="kakao_login"
            className={cn("kakao-login")}
            onClick={login}
          />
          <img
            src={getUrlPath("login/kakao_login_mo.png")}
            alt="kakao_login_mo"
            className={cn("kakao-login-mo")}
            onClick={login}
          />
        </button>
      </div>
    </div>
  );
};

export default Login;
