import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useAlert, useAuth, useConfirm } from '../../../../contexts';
import { useNavigate } from 'react-router-dom';
import authApi from '../../../../api/auth';

export type reasonType =
  | 'OPT01'
  | 'OPT02'
  | 'OPT04'
  | 'OPT08'
  | 'OPT16'
  | 'OPT32';

const useSecession = () => {
  const navigate = useNavigate();
  const { alert } = useAlert();
  const { confirm } = useConfirm();
  const { logout } = useAuth();

  const [reason, setReason] = useState<reasonType | ''>('');
  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [secession, setSecession] = useState(false);

  const reasons: {
    key: string;
    text: string;
    code: reasonType;
  }[] = useMemo(
    () => [
      { key: 'focus_university', text: '대학에 합격했어요.', code: 'OPT01' },
      {
        key: 'no_info_major',
        text: '원하는 학과의 면접 정보가 없어요.',
        code: 'OPT02',
      },
      {
        key: 'late_feedback',
        text: '피드백이 너무 늦게 제공돼요.',
        code: 'OPT04',
      },
      {
        key: 'unexpected_questions',
        text: '예상 질문이 너무 변하고, 실제 면접에 나올 것 같지 않아요.',
        code: 'OPT08',
      },
      {
        key: 'prefer_other_site',
        text: '타 사이트가 더 좋아요.',
        code: 'OPT16',
      },
      { key: 'other', text: '기타', code: 'OPT32' },
    ],
    [],
  );

  const handleReasonChange = useCallback(() => {
    // 최소 10글자 이상 입력해야 함
    if (input.length < 10) {
      setError('최소 10글자 이상을 입력해 주세요.');
    } else {
      setError(''); // 에러 없을 때
    }
  }, [input]);

  const handleTermsChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setTermsAgreed(e.target.checked),
    [],
  );

  const handleInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value),
    [],
  );

  const buttonDisabled = useCallback(() => {
    if (!reason) {
      setError(''); // 에러 없을 때
      alert({
        title: '필수 항목을 확인해 주세요.',
        subtitle: '필수 항목을 체크하신 후 탈퇴 신청이 가능해요.',
      });
    } else {
      console.log(reason);
      if (reason === 'OPT32') {
        handleReasonChange();
        if (error || input.length <= 10) {
          alert({
            title: '기타 사항을 입력해주세요.',
            subtitle: '최소 10글자 이상으로 적어주세요.',
          });
          return;
        }
      }
    }

    confirm({
      title: "정말<span style='color: #e44a4a;'>탈퇴</span>하시겠어요?",
      subtitle:
        '지금 탈퇴하시면 무료 혜택과 <br/>  분석받은 면접 질문 등 모든 정보가 사라집니다. <br/> 정말 탈퇴하시겠습니까?',
      onConfirm: () => {
        navigate('/');
      },
      onClose: async () => {
        const response = await authApi.leave({
          leavedReasonType: reason as reasonType,
          reasonComment: input,
        });

        if (response.code === '2000') {
          setSecession(true);
          logout(true);
          window.scrollTo({ top: 0, left: 0 });
        }
      },
      confirmButtonName: '메인 바로가기',
      closeButtonName: '탈퇴 할께요',
    });
  }, [alert, confirm, error, handleReasonChange, input, navigate, reason]);

  const handlePrevButtonClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return {
    handleTermsChange,
    handleInput,
    buttonDisabled,
    secession,
    termsAgreed,
    reasons,
    setReason,
    setError,
    error,
    reason,
    input,
    handleReasonChange,
    handlePrevButtonClick,
  };
};
export default useSecession;
