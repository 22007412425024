import React from "react";

export interface StackProps {
  direction?: "vertical" | "horizontal"; // 기본 방향 설정
  responsive?: boolean; // 반응형 전환 여부
  gap?: string;
  className?: string;
  children: React.ReactNode;
}

const Stack = ({
  direction = "vertical",
  responsive = false,
  gap = "gap-4",
  className = "",
  children,
}: StackProps) => {
  const baseDirection = direction === "vertical" ? "flex-col" : "flex-row";
  const responsiveDirection = responsive ? "sm:flex-row" : "";

  return (
    <div
      className={`flex ${baseDirection} ${responsiveDirection} ${gap} ${className}`}
    >
      {children}
    </div>
  );
};

export default Stack;
