import React from 'react';
import styles from '../Result.module.scss'; // SCSS 파일을 import
import { getUrlPath } from '../../../../utils';
import { Card } from '../../../../components';
import useNoData from './hooks/useNoData';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const NoData = () => {
  const { handleGoToInterviewQuestion, handleGoToLifeRecodeWrite } =
    useNoData();
  return (
    <>
      <div className={cn('no-data')}>
        <img src={getUrlPath('empty_box.png')} alt="No Data" />
        <p>아직 등록된 면접 질문이 없어요.</p>
      </div>

      <div className={cn('action-buttons')}>
        <Card
          title="면접 예상 질문 접수하기"
          subtitle="목표보다, 잘했을 준비하셨나요?"
          onClick={() => handleGoToInterviewQuestion()}
        />
        <Card
          title="생기부 등록 바로가기"
          subtitle="생기부가 준비되셨나요?"
          onClick={handleGoToLifeRecodeWrite}
        />
      </div>
    </>
  );
};

export default NoData;
