import React from 'react';
import classNames from 'classnames/bind';
import styles from './Footer.module.scss'; // SCSS 모듈 가져오기
import Stack from '../Stack';
import { getUrlPath } from '../../utils';
import useFooter from './hooks/useFooter';

const cn = classNames.bind(styles);
const Footer = () => {
  const { handleGoToInstagram, handleGoToPrivacy, handleGoToTerms } =
    useFooter();

  return (
    <footer className={cn('footer')}>
      <div className={cn('content')}>
        <Stack className={cn('content-gap')}>
          <div className={cn('left')}>
            <span className={cn('link')} onClick={handleGoToTerms}>
              이용약관
            </span>
            <span className={cn('link')} onClick={handleGoToPrivacy}>
              개인정보처리방침
            </span>
          </div>
          <div className={cn('right')}>
            <span>
              (주)펀블 | 대표이사 조찬식 | 사업자등록번호 822-87-01619 |
            </span>
            <br />
            <span>대표전화 1661-3258 | soosi@funble.kr</span>
            <span> 서울특별시 의사당대로83, 오투타워 18층 | &nbsp; </span>
            <span className={cn('customer-service')}>
              고객센터 운영시간 평일 09시~18시(점심 12시~13시)
            </span>
            {/* 고객센터만 줄바꿈 처리 */}
          </div>
        </Stack>
      </div>
      <div className={cn('icons')}>
        <div className={cn('icon')} onClick={handleGoToInstagram}>
          <img
            src={getUrlPath(`instagram_icon.png`)}
            alt="instagram_icon"
            className={cn('instagram_icon')}
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
