import React, { useEffect, useState } from 'react';
import styles from './Detail.module.scss';
import classNames from 'classnames/bind';
import { getUrlPath } from '../../../../utils';
import recordQuestionAnalyze from '../../../../api/record-question-analyze';

const cn = classNames.bind(styles);

export interface DetailItemProps {
  id: string;
  question: string;
  answer: string;
  idx: number;
  index: number;
  handleAnswerChange: (index: number, value: string) => void;
}

const DetailItem = ({
  id,
  question,
  answer,
  idx,
  index,
  handleAnswerChange: propsHandleAnswerChange,
}: DetailItemProps) => {
  const [isOpen, setIsOpen] = useState(false); // 질문 열림/닫힘 상태
  const [disabled, setDisabled] = useState(false);
  const [localAnswer, setLocalAnswer] = useState<string>(answer); // 로컬 상태 관리

  const toggleItem = () => {
    setIsOpen(!isOpen); // 클릭 시 토글
  };

  const handleClickUpdate = async () => {
    if (disabled) {
      setDisabled(!disabled);
    } else {
      const response = await recordQuestionAnalyze.createRecodeQuestionMyAnswer(
        {
          analyzedQuestionsIdx: idx,
          myAnswer: localAnswer,
        },
      );

      if (response.code === '2000') {
        setDisabled(true);
        propsHandleAnswerChange(index, localAnswer);
      }
    }
  };

  const endsWithOne = (value: number | string) => {
    return value.toString().endsWith('1');
  };

  // 로컬 상태 변경 핸들러
  const handleAnswerChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const updatedAnswer = event.target.value;
    setLocalAnswer(updatedAnswer); // 로컬 상태 업데이트
  };

  useEffect(() => {
    setDisabled(answer.length > 0);
    setLocalAnswer(answer);

    setIsOpen(endsWithOne(id) || answer.length > 0);
  }, [answer, id]);

  return (
    <div className={cn('item-container')}>
      <div
        className={cn('question', {
          'answer-open': isOpen,
        })}
        onClick={toggleItem}
      >
        <div className={cn('question-text')}>
          <div className={cn('question-text-index')}>{id}</div>
          <div>{question}</div>
        </div>
        <div className={cn('icon')}>
          <img
            src={getUrlPath(isOpen ? 'up_icon.png' : 'down_icon.png')}
            alt="icon"
          />
        </div>
      </div>

      {isOpen && (
        <div className={cn('answer')}>
          <textarea
            disabled={disabled}
            defaultValue={localAnswer}
            onChange={handleAnswerChange} // 변경 시 호출
          />
          <button className={cn('save-button')} onClick={handleClickUpdate}>
            {disabled ? '수정' : '저장'}
          </button>
        </div>
      )}
    </div>
  );
};

export default DetailItem;
