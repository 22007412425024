import React from 'react';
import classNames from 'classnames/bind';
import { getUrlPath } from '../../../utils';
import styles from './Secession.module.scss';
import useSecession from './hooks/useSecession';

const cn = classNames.bind(styles);

const Secession = () => {
  const {
    buttonDisabled,
    handleInput,
    handleTermsChange,
    reasons,
    secession,
    setReason,
    termsAgreed,
    setError,
    error,
    reason,
    input,
    handleReasonChange,
    handlePrevButtonClick,
  } = useSecession();

  return (
    <div className={cn('container')}>
      {!secession && (
        <div className={cn('form-card')}>
          <h1 className={cn('title')}>회원 탈퇴 사유 및 유의사항</h1>
          <p className={cn('subtitle')}>
            더 나은 서비스 제공을 위해 탈퇴사유(필수)를 선택해 주세요.
          </p>
          <div className={cn('divider')} />
          <div className={cn('container-gap')}>
            <h2 className={cn('section-title')}>(필수)회원 탈퇴 사유</h2>
            <div className={cn('radio-group')}>
              {reasons.map(item => {
                return (
                  <div className={cn('radio-option')} key={item.key}>
                    <input
                      type="radio"
                      name="reason"
                      value={item.code}
                      className={cn('radio-input')}
                      onChange={() => {
                        setError(''); // 에러 없을 때
                        setReason(item.code);
                      }}
                    />
                    <label>{item.text}</label>
                  </div>
                );
              })}
            </div>

            {/* 탈퇴 이유 input 필드 (기타를 선택한 경우만 표시) */}
            <input
              type="text"
              className={error ? cn('input-error') : cn('input-normal')}
              placeholder="탈퇴 이유는"
              disabled={reason !== 'OPT32'}
              value={input}
              onChange={handleInput}
              onBlur={handleReasonChange}
            />
            {error && <p className={cn('error-message')}>{error}</p>}
          </div>

          <div className={cn('divider')} />

          {/* 유의사항 */}
          <div className={cn('section')}>
            <h2 className={cn('section-title')}>(필수)유의사항</h2>
            <div className={cn('notice')}>
              <ul>
                <li>
                  가입한 회원 개인 정보는 모두 삭제되며, 기타 정보는 암호화
                  처리되어 복구가 불가능하게 돼요.
                </li>
                <li>박람회 쿠폰, 이벤트 당첨 혜택 등은 소멸돼요.</li>
                <li>
                  그 외 사용자의 탈퇴로 인한 어떠한 불이익에 대해서도 책임을
                  지지 않아요.
                </li>
              </ul>
              <div className={cn('notice-checked')}>
                <input
                  className={cn('checkbox-input')}
                  type="checkbox"
                  checked={termsAgreed}
                  onChange={handleTermsChange}
                />
                <label>탈퇴 시 유의사항을 모두 확인했어요.</label>
              </div>
            </div>
          </div>
          <div className={cn('button')}>
            <button
              className={cn('prev-button')}
              onClick={handlePrevButtonClick}
            >
              이전
            </button>

            <button
              className={cn('next-button')}
              onClick={buttonDisabled}
              disabled={!termsAgreed}
            >
              다음
            </button>
          </div>
        </div>
      )}

      {secession && (
        <div className={cn('card')}>
          {/* 아이콘 (체크 마크) */}
          <div className={cn('icon')}>
            <img src={getUrlPath(`radio_check.png`)} alt="radio_check" />
          </div>
          {/* 타이틀 */}
          <h1 className={cn('title')}>탈퇴완료</h1>
          {/* 서브타이틀 */}
          <p className={cn('subtitle')}>
            수시탐탐을 이용해주셔서 <br className={cn('br-tag')} /> 진심으로
            감사드립니다. <br />
            여러분의 꿈을 항상 응원하겠습니다!
          </p>
        </div>
      )}
    </div>
  );
};

export default Secession;
