import axios from 'axios';
import { getItem, setItem } from './sessionStorage';
import { SESSION_STORAGE } from '../constants';
import authApi from '../api/auth';

// Axios 인스턴스 생성
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
});

let isRefreshing = false; // 현재 토큰 갱신 요청 중인지 추적하는 변수
let refreshSubscribers: ((token: string) => void)[] = [];

const onTokenRefreshed = (token: string) => {
  refreshSubscribers.map(callback => callback(token));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

export const refreshToken = async (): Promise<string | null> => {
  try {
    const response = await authApi.refresh({
      refreshToken: getItem(
        `${SESSION_STORAGE.init}${SESSION_STORAGE.refreshToken}`,
      ) as string,
    });
    const newAccessToken = response.data?.accessToken || '';

    // 새 토큰 저장
    setItem(
      `${SESSION_STORAGE.init}${SESSION_STORAGE.loginToken}`,
      newAccessToken,
    );

    setItem(
      `${SESSION_STORAGE.init}${SESSION_STORAGE.refreshToken}`,
      response.data?.refreshToken || '',
    );

    api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;

    // 구독자들에게 새 토큰을 전달하여 대기 중인 요청을 재시도
    onTokenRefreshed(newAccessToken);

    return newAccessToken;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    return null;
  }
};

// 요청 인터셉터 설정
api.interceptors.request.use(
  config => {
    const token = getItem(
      `${SESSION_STORAGE.init}${SESSION_STORAGE.loginToken}`,
    );

    if (token && config.headers) {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      if (config.headers && config.headers['Content-Type']) {
        // eslint-disable-next-line no-self-assign
        config.headers['Content-Type'] = config.headers['Content-Type'];
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// 응답 인터셉터 설정
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, please log in again.');
      // 이미 갱신 요청 중이라면, 대기하고 새 토큰으로 요청 재시도
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newToken = await refreshToken();
          isRefreshing = false;

          if (newToken) {
            // 기존 요청의 헤더를 새 토큰으로 업데이트
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            // 요청을 재시도
            return api(originalRequest);
          }
        } catch (refreshError) {
          isRefreshing = false;
          return Promise.reject(refreshError);
        }
      }

      // 다른 요청들이 토큰 갱신을 기다리도록 설정
      return new Promise(resolve => {
        addRefreshSubscriber((newToken: string) => {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          resolve(api(originalRequest));
        });
      });
    }
    return Promise.reject(error);
  },
);

// GET 요청
export const get = async <T>(url: string, params?: object): Promise<T> => {
  try {
    const response = await api.get<T>(url, { params });
    return response.data;
  } catch (e) {
    let errorMessage = 'An unknown error occurred';

    if (e instanceof Error) {
      // `e`가 `Error` 객체일 경우, `message` 속성을 통해 에러 메시지 접근
      errorMessage = e.message;
    } else if (typeof e === 'string') {
      // 에러가 문자열로 발생할 경우
      errorMessage = e;
    } else if (typeof e === 'object' && e !== null && 'message' in e) {
      // 에러가 객체 형태이고 `message` 속성을 가지고 있다면
      errorMessage = (e as { message: string }).message;
    }

    return { code: '10500', message: errorMessage } as T;
  }
};

// POST 요청
export const post = async <T, U>(
  url: string,
  data: U,
  contentType: string = 'application/json',
): Promise<T> => {
  try {
    const response = await api.post<T>(url, data, {
      headers: {
        'Content-Type': contentType,
      },
    });
    return response.data;
  } catch (e) {
    let errorMessage: string = 'An unknown error occurred';

    if (e instanceof Error) {
      // `e`가 `Error` 객체일 경우, `message` 속성을 통해 에러 메시지 접근
      errorMessage = e.message;
    } else if (typeof e === 'string') {
      // 에러가 문자열로 발생할 경우
      errorMessage = e;
    } else if (typeof e === 'object' && e !== null && 'message' in e) {
      // 에러가 객체 형태이고 `message` 속성을 가지고 있다면
      errorMessage = (e as { message: string }).message;
    }

    return { code: '10500', message: errorMessage } as T;
  }
};

// PUT 요청
export const put = async <T, U>(
  url: string,
  data: U,
  contentType: string = 'application/json',
): Promise<T> => {
  try {
    const response = await api.put<T>(url, data, {
      headers: {
        'Content-Type': contentType,
      },
    });
    return response.data;
  } catch (e) {
    let errorMessage = 'An unknown error occurred';

    if (e instanceof Error) {
      // `e`가 `Error` 객체일 경우, `message` 속성을 통해 에러 메시지 접근
      errorMessage = e.message;
    } else if (typeof e === 'string') {
      // 에러가 문자열로 발생할 경우
      errorMessage = e;
    } else if (typeof e === 'object' && e !== null && 'message' in e) {
      // 에러가 객체 형태이고 `message` 속성을 가지고 있다면
      errorMessage = (e as { message: string }).message;
    }

    return { code: '10500', message: errorMessage } as T;
  }
};

// DELETE 요청
export const del = async <T>(
  url: string,
  contentType: string = 'application/json',
): Promise<T> => {
  try {
    const response = await api.delete<T>(url, {
      headers: {
        'Content-Type': contentType,
      },
    });
    return response.data;
  } catch (e) {
    let errorMessage = 'An unknown error occurred';

    if (e instanceof Error) {
      // `e`가 `Error` 객체일 경우, `message` 속성을 통해 에러 메시지 접근
      errorMessage = e.message;
    } else if (typeof e === 'string') {
      // 에러가 문자열로 발생할 경우
      errorMessage = e;
    } else if (typeof e === 'object' && e !== null && 'message' in e) {
      // 에러가 객체 형태이고 `message` 속성을 가지고 있다면
      errorMessage = (e as { message: string }).message;
    }

    return { code: '10500', message: errorMessage } as T;
  }
};

export default api;
