import { Dispatch, SetStateAction, useCallback } from 'react';
import { useAlert } from '../../../../../contexts';
import { RecordQuestionsGeneratedRequest } from '../../../../../types/record-question-analyze/api';
export interface UseCardItem {
  setResult: Dispatch<SetStateAction<boolean>>;
  setGeneratedParam: Dispatch<SetStateAction<RecordQuestionsGeneratedRequest>>;
}

const useCardItem = ({ setResult, setGeneratedParam }: UseCardItem) => {
  const { alert } = useAlert();

  const handleGotoResultDetail = useCallback(
    (statue: string, recodeIdx: number, admissionIdx: number) => {
      if (statue !== 'DONE') {
        alert({
          title: '면접 전문가가 <br /> 꼼꼼하게 분석 중이에요!',
          subtitle:
            "<div class='medium-notbold-text text-base-gray'>대치동 컨설턴트 경력을 지닌 면접 전문가가 <br /> 생활기록부를 분석하고, 예상 질문을 생성하고 있습니다. <br /> 조금만 기다려주세요!</div>",
        });
        return;
      }

      setGeneratedParam({
        studentRecordAnalyzeIdx: admissionIdx,
        studentRecordIdx: recodeIdx,
      });

      setResult(true);
    },
    [alert, setResult, setGeneratedParam],
  );

  return { handleGotoResultDetail };
};

export default useCardItem;
