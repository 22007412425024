import React, { useCallback, useEffect, useState } from 'react';
import styles from './Write.module.scss';
import { Card } from '../../../components';
import classNames from 'classnames/bind';
import FileUploadCard from './FileUploadCard';
import RecodeForm from './RecodeForm';
import { useNavigate } from 'react-router-dom';
import recodeApi from '../../../api/recode';
import { StudentRecord } from '../../../types/recode/api';
import { some } from 'lodash-es';

const cn = classNames.bind(styles);

const Write = () => {
  const navigate = useNavigate();

  // 최초에 데이터가 있니?
  const [init, setInit] = useState(false);
  const [detailNo, setDetailNo] = useState<number>(0);
  const [list, setList] = useState<StudentRecord[]>([]);
  const [isApi, setIsApi] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState<boolean>(false);

  const getRecodeList = useCallback(async () => {
    const response = await recodeApi.getRecodeList();

    if (response.code === '2000') {
      if (response.data) {
        setInit(!!response.data.length);
        setList(response.data);
        const hasFileInUse = some(response.data, { isFileUse: true });

        setIsFileUpload(hasFileInUse);
      } else {
        setInit(false);
      }
    }

    setIsApi(true);
  }, []);

  const handleGoToInstructions = useCallback(
    (type?: string) => {
      navigate(`/instructions${type ? `?type=${type}` : ''}`);
    },
    [navigate],
  );

  useEffect(() => {
    getRecodeList().then();
  }, []);

  if (!isApi) {
    return <></>;
  }

  return (
    <div className={cn('container')}>
      <h1 className={cn('title')}>생기부 등록</h1>

      {init && (
        <FileUploadCard
          setInit={setInit}
          setDetailId={setDetailNo}
          list={list}
          getRecodeList={getRecodeList}
        />
      )}

      {!init && (
        <RecodeForm
          detailNo={detailNo}
          setInit={setInit}
          getRecodeList={getRecodeList}
        />
      )}

      {!isFileUpload && (
        <div className={cn('action-buttons')}>
          <Card
            title={'정부24 생기부 <br /> 다운로드 방법'}
            onClick={() => handleGoToInstructions()}
          />
          <Card
            title={'나이스 플러스 생기부 <br /> 다운로드 방법'}
            onClick={() => handleGoToInstructions('nice')}
          />
        </div>
      )}
    </div>
  );
};

export default Write;
