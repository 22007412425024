import React from "react";
import classNames from "classnames/bind";
import styles from "./Card.module.scss";
import { getUrlPath } from "../../utils";

const cn = classNames.bind(styles);

export interface CardProps {
  title: string | TrustedHTML;
  subtitle?: string;
  moreText?: string;
  onClick: () => void;
}
const Card = ({ title, subtitle, onClick, moreText }: CardProps) => {
  return (
    <div className={cn("container")} onClick={onClick}>
      <div
        className={cn("card", {
          "flex-end": !!moreText,
        })}
      >
        <div className={cn("text-content")}>
          <div
            className={cn("title")}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {subtitle && <div className={cn("subtitle")}>{subtitle}</div>}
        </div>
        <div className={cn("arrow-icon")}>
          {!moreText && (
            <img
              src={getUrlPath(`array_icon.png`)}
              alt={`array_icon`}
              className={cn("array_icon")}
            />
          )}

          {moreText && (
            <>
              <div className={cn("arrow-text")}>{moreText}</div>
              <img
                className={cn("arrow-text-icon")}
                src={getUrlPath(`right_small.png`)}
                alt="right_small"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
