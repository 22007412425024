import React, { useCallback, useRef, useState } from 'react';
import styles from './RequestForm.module.scss';
import { getCalculateGrade, getUrlPath } from '../../../../utils';
import { Popup } from '../../../../components';
import classNames from 'classnames/bind';
import { StudentRecord } from '../../../../types/recode/api';
import {
  UniversityAdmission,
  UniversityDepartment,
  UniversityResponse,
} from '../../../../types/common/api';
import commonApi from '../../../../api/common';
import { useAlert } from '../../../../contexts';
import predictQuestions from '../../../../api/predict-questions';
import {
  CollegesAdmissionsListResponse,
  CreateCollegesAdmissionsRequest,
} from '../../../../types/predict-questions/api';
import { useNavigate } from 'react-router-dom';

export interface RequestFormProps {
  isLifeRecode: boolean;
  userData: StudentRecord[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
}

const cn = classNames.bind(styles);

const RequestForm = ({ userData, setStep, step }: RequestFormProps) => {
  const [createCollegesAdmissionsRequest, setCreateCollegesAdmissionsRequest] =
    useState<CreateCollegesAdmissionsRequest[]>([]);
  const [response, setResponse] = useState<
    CollegesAdmissionsListResponse[] | null
  >(null);

  const universityDropdownRef = useRef<HTMLDivElement>(null);
  const [isUniversitySearch, setIsUniversitySearch] = useState(false);
  const majorDropdownRef = useRef<HTMLDivElement>(null);
  const [isMajorSearch, setIsMajorSearch] = useState(false);
  const [majorItems, setMajorItems] = useState<UniversityDepartment[]>([]);

  const typeDropdownRef = useRef<HTMLDivElement>(null);
  const [isTypeSearch, setIsTypeSearch] = useState(false);
  const [typeItems, setTypeItems] = useState<UniversityAdmission[]>([]);
  const navigate = useNavigate();

  const { alert } = useAlert();

  // 지망 6개!
  const [totalList] = useState<
    {
      university: UniversityResponse | null;
      major: UniversityDepartment | null;
      type: UniversityAdmission | null;
    }[]
  >(
    Array.from({ length: 6 }, () => ({
      university: null,
      major: null,
      type: null,
    })),
  );
  const [done, setDone] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<number>(0);
  const [selectListIndex, setSelectListIndex] = useState<number>(0);
  const [universityResult, setUniversityResults] = useState<
    UniversityResponse[]
  >([]);
  const [majorResult, setMajorResults] = useState<UniversityDepartment[]>([]);
  const [typeResult, setTypeResults] = useState<UniversityAdmission[]>([]);
  const [errorAlertMsg, setErrorAlertMsg] = useState<{
    title: string;
    subtitle: string;
  }>({
    title: '수시지원정보 입력 안내',
    subtitle: '1지망(학교, 학과, 전형)입력 후 이용이 가능해요.',
  });

  const [universitySearch, setUniversitySearch] = useState<string[]>(
    Array.from({ length: 6 }, () => ''),
  );
  const [majorSearch, setMajorSearch] = useState<string[]>(
    Array.from({ length: 6 }, () => ''),
  );
  const [typeSearch, setTypeSearch] = useState<string[]>(
    Array.from({ length: 6 }, () => ''),
  );

  const handleSelectListIndex = (index: number) => {
    setSelectListIndex(index);
  };

  const clickNextButton = (step: number) => {
    switch (step) {
      case 1:
        {
          let flag = false;
          for (let i = 0; i < totalList.length; i++) {
            if (i === 0) {
              if (
                totalList[i].university === null ||
                totalList[i].type === null ||
                totalList[i].major === null
              )
                setErrorAlert(true);
              else {
                flag = true;
              }
            } else {
              if (totalList[i].university !== null) {
                if (totalList[i].type === null) {
                  setErrorAlertMsg({
                    title: '수시전형 입력 안내',
                    subtitle: '수시전형을 입력하신 후에 이용이 가능해요.',
                  });
                  setErrorAlert(true);
                  setDropdownOpen(i);
                  flag = false;
                }
                if (totalList[i].major === null) {
                  setErrorAlertMsg({
                    title: '학과명 입력 안내',
                    subtitle: '학과 입력한 후에 이용이 가능해요.',
                  });
                  setErrorAlert(true);
                  setDropdownOpen(i);
                  flag = false;
                }
              }
            }
          }

          if (flag) {
            totalList.map(item => {
              if (item.major && item.type)
                createCollegesAdmissionsRequest.push({
                  studentRecordIdx: userData[0].studentRecordIdx,
                  collegeIdx: item.major.collegesIdx,
                  collegesAdmissionsIds: item.type.collegesAdmissionsIdx,
                });
            });
            predictQuestions
              .createCollegesAdmissions(createCollegesAdmissionsRequest)
              .then(() => {
                predictQuestions
                  .getCollegesAdmissionsList({
                    studentRecordIdx: userData[0].studentRecordIdx,
                  })
                  .then(data => setResponse(data.data || null));
              });
            setCreateCollegesAdmissionsRequest([]);
            setStep(step + 1);
          }
        }
        break;
      case 0:
        setStep(step + 1);
        break;
      case 2:
        setDone(true);
        break;
      case 3:
        navigate('/interview/result');
        break;
      default:
        setErrorAlertMsg({
          title: '잘못된 접근입니다.',
          subtitle: '다시 시도해주세요.',
        });
        setErrorAlert(true);
    }
  };

  const handleUniversitySearch = useCallback(
    async (index: number) => {
      if (isUniversitySearch && universityResult.length) {
        setIsUniversitySearch(false);
        return;
      }

      if (!universitySearch || universitySearch.length < 2) {
        alert({ title: '대학교 이름을 2글자 이상 입력해 주세요.' });
        return;
      }

      const response = await commonApi.getUniversityList({
        searchName: universitySearch[index],
      });

      if (response.code === '2000') {
        setIsUniversitySearch(true);
        if (response?.data) {
          setUniversityResults(response.data);
        } else {
          setUniversityResults([]);
        }
      }
    },
    [universitySearch, isUniversitySearch, universityResult],
  );

  const searchTypeItems = (input: string) => {
    // 배열이 있다면, 해당 배열로 검색하고 리턴
    if (typeItems) {
      const filteredItems = typeItems.filter(item => {
        // 각 키에 대해 검색어를 포함하는지 검사
        return (
          item.admissionsPrimaryName +
          '-' +
          item.admissionsTertiaryName
        ).includes(input);
      });

      setTypeResults(filteredItems);
    }
    // 그렇지 않다면, api 호출
    else {
    }
  };
  const searchMajorItems = (input: string) => {
    // 배열이 있다면, 해당 배열로 검색하고 리턴
    if (majorItems) {
      const filteredItems = majorItems.filter(item => {
        // 각 키에 대해 검색어를 포함하는지 검사
        return item.collegeDepartmentsName.includes(input);
      });
      setMajorResults(filteredItems);
    }
    // 그렇지 않다면, api 호출
    else {
    }
  };

  const handleTypeSearch = useCallback(
    async (index: number) => {
      if (!totalList[index].university) {
        alert({ title: '대학교를 먼저 선택해주세요' });
        setIsTypeSearch(false);
        return;
      }

      setIsTypeSearch(true);
      if (typeItems) {
        searchTypeItems(typeSearch[index]);
      } else {
        setTypeResults([]);
      }
    },
    [typeSearch, isTypeSearch, typeResult],
  );

  const handleMajorSearch = useCallback(
    async (index: number) => {
      if (!totalList[index].university) {
        alert({ title: '대학교를 먼저 선택해주세요' });
        setIsMajorSearch(false);
        return;
      }

      setIsMajorSearch(true);
      if (majorItems) {
        searchMajorItems(majorSearch[index]);
      } else {
        setMajorResults([]);
      }
    },
    [majorSearch, isMajorSearch, majorResult],
  );

  const handleUniversityChange = useCallback(
    async (index: number, value: string) => {
      setUniversitySearch(prevStrings =>
        prevStrings.map((item, i) => (i === index ? value : item)),
      );

      if (totalList[index].university) {
        const response = await commonApi.getUniversityDepartmentsList({
          universityIdx: totalList[index].university?.idx!!,
        });
        handleMajorChange(index, '');
        handleTypeChange(index, '');
        setTypeItems(response.data?.collegesAdmissionsList!!);
        setMajorItems(response.data?.collegesDepartmentsList!!);
      }
    },
    [],
  );

  const handleTypeChange = useCallback((index: number, value: string) => {
    setTypeSearch(prevStrings =>
      prevStrings.map((item, i) => (i === index ? value : item)),
    );
  }, []);

  const handleMajorChange = useCallback((index: number, value: string) => {
    setMajorSearch(prevStrings =>
      prevStrings.map((item, i) => (i === index ? value : item)),
    );
  }, []);

  return (
    <div className={cn('container')}>
      <div className={cn('title')}>
        {step === 1 && (
          <div className={cn('content')}>1. 지원대학/학과 입력</div>
        )}
        {step === 2 && (
          <div className={cn('content')}>2. 면접 예상 질문 생성</div>
        )}
        {(step === 1 || step === 2) && (
          <div className={cn('number')}>
            <div
              className={cn({
                'select-round-num': step === 1,
                'round-num': step !== 1,
              })}
            >
              1
            </div>
            <div
              className={cn({
                'select-round-num': step === 2,
                'round-num': step !== 2,
              })}
            >
              2
            </div>
          </div>
        )}
      </div>
      {(step === 1 || step === 2) && (
        <div className={cn('user-info')}>
          {userData[0].studentName} | {userData[0].eduSchoolNmKr} |{' '}
          {getCalculateGrade(userData[0].studentEntryYear, false)}
        </div>
      )}
      {step === 1 && (
        <div className={cn('subtitle')}>
          <div>2025년 수시 지원대학을 입력해 주세요.</div>
          <div>(최소 1개 필수)</div>
        </div>
      )}
      {step === 2 && (
        <div className={cn('subtitle')}>
          <div>면접 예상질문 생성을 원하는 지원대학 한곳을 선택해 주세요.</div>
          <div>(1개 선택 필수)</div>
        </div>
      )}
      {/* 드롭카드 컴포넌트로 묶어서 반복돌리기? */}
      {step === 1 && (
        <div>
          {totalList.map((value, listIndex) => {
            return (
              <div className={cn('drop-card')} key={listIndex}>
                <div
                  onClick={() => {
                    setDropdownOpen(
                      dropdownOpen === listIndex ? -1 : listIndex,
                    );
                  }}
                  className={cn('drop-title')}
                >
                  <div>
                    {listIndex + 1}지망
                    {listIndex === 0 && <div className={cn('asterisk')}>*</div>}
                  </div>
                  <img
                    src={getUrlPath(
                      dropdownOpen === listIndex
                        ? 'up_icon.png'
                        : 'down_icon.png',
                    )}
                    alt={
                      dropdownOpen === listIndex
                        ? 'up_icon.png'
                        : 'down_icon.png'
                    }
                  />
                </div>
                {/* 대학교 명 검색 및 자동완성 드롭다운 */}
                <div
                  className={cn(`drop-detail`, {
                    active: dropdownOpen === listIndex,
                  })}
                >
                  <div className={cn('form-group')} ref={universityDropdownRef}>
                    <div className={cn('label')}>대학교 명</div>
                    <div className={cn('dropdown-wrapper')}>
                      <input
                        type="text"
                        className={cn('input')}
                        placeholder="대학교 이름을 2글자 이상 입력해 주세요."
                        value={universitySearch[listIndex]}
                        onChange={e => {
                          handleUniversityChange(listIndex, e.target.value);
                        }} // 검색어 입력 시 검색 실행
                      />
                      <button
                        className={cn('search-button')}
                        onClick={() => handleUniversitySearch(listIndex)}
                      >
                        검색
                      </button>

                      {isUniversitySearch &&
                        (universityResult.length > 0 ? (
                          <ul className={cn('dropdown-menu')}>
                            {universityResult.map((school, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  if (school) {
                                    totalList[listIndex].university = school;
                                    handleUniversityChange(
                                      listIndex,
                                      school.universityName,
                                    );
                                    setIsUniversitySearch(false);
                                  }
                                }}
                              >
                                <div>{school.universityName}</div>
                                <div>{school.locationName}</div>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <ul className={cn('dropdown-menu')}>
                            <div className={cn('no-results')}>
                              <img
                                src={getUrlPath('empty_box.png')}
                                alt="No Results"
                              />
                              <span>검색 결과가 없어요.</span>
                            </div>
                          </ul>
                        ))}
                    </div>
                  </div>
                  {/* 학과 명 검색 및 자동완성 드롭다운 */}
                  <div className={cn('form-group')} ref={majorDropdownRef}>
                    <div className={cn('label')}>학과 명</div>
                    <div className={cn('dropdown-wrapper')}>
                      <input
                        type="text"
                        className={cn('input')}
                        disabled={totalList[listIndex].university === null}
                        placeholder="학과 명을 2글자 이상 입력해 주세요."
                        value={majorSearch[listIndex]}
                        onChange={e => {
                          handleMajorChange(listIndex, e.target.value);
                        }} // 검색어 입력 시 검색 실행
                      />
                      <button
                        className={cn('search-button')}
                        onClick={() => handleMajorSearch(listIndex)}
                      >
                        검색
                      </button>

                      {isMajorSearch &&
                        (majorResult.length > 0 ? (
                          <ul className={cn('dropdown-menu')}>
                            {majorResult.map((major, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  if (major) {
                                    totalList[listIndex].major = major;
                                    handleMajorChange(
                                      listIndex,
                                      major.collegeDepartmentsName,
                                    );
                                    setIsMajorSearch(false);
                                  }
                                }}
                              >
                                {major.collegeDepartmentsName}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <ul className={cn('dropdown-menu')}>
                            <div className={cn('no-results')}>
                              <img
                                src={getUrlPath('empty_box.png')}
                                alt="No Results"
                              />
                              <span>검색 결과가 없어요.</span>
                            </div>
                          </ul>
                        ))}
                    </div>
                  </div>
                  {/* 전형 명 검색 및 자동완성 드롭다운 */}
                  <div className={cn('form-group')} ref={typeDropdownRef}>
                    <div className={cn('label')}>전형 명</div>
                    <div className={cn('dropdown-wrapper')}>
                      <input
                        type="text"
                        className={cn('input')}
                        disabled={totalList[listIndex].university === null}
                        placeholder="입시전형 명을 2글자 이상 입력해 주세요."
                        value={typeSearch[listIndex]}
                        onChange={e => {
                          handleTypeChange(listIndex, e.target.value);
                        }} // 검색어 입력 시 검색 실행
                      />
                      <button
                        className={cn('search-button')}
                        onClick={() => handleTypeSearch(listIndex)}
                      >
                        검색
                      </button>

                      {isTypeSearch &&
                        (typeResult.length > 0 ? (
                          <ul className={cn('dropdown-menu')}>
                            {typeResult.map((type, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  if (type) {
                                    totalList[listIndex].type = type;
                                    handleTypeChange(
                                      listIndex,
                                      type.admissionsPrimaryName +
                                        '-' +
                                        type.admissionsTertiaryName,
                                    );
                                    setIsTypeSearch(false);
                                  }
                                }}
                              >
                                {type.admissionsPrimaryName +
                                  '-' +
                                  type.admissionsTertiaryName}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <ul className={cn('dropdown-menu')}>
                            <div className={cn('no-results')}>
                              <img
                                src={getUrlPath('empty_box.png')}
                                alt="No Results"
                              />
                              <span>검색 결과가 없어요.</span>
                            </div>
                          </ul>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {step === 2 && (
        <div className={cn('select-card')}>
          {/*이것도 컴포넌트해서 받은 리스트 돌리기?*/}
          {response?.map((item, index) => {
            return (
              item.collegeDepartmentName &&
              item.universityName &&
              item.collegeAdmissionName && (
                <div
                  onClick={() => handleSelectListIndex(index)}
                  className={cn(`select-card-detail`, {
                    select: index === selectListIndex,
                  })}
                  key={item.idx}
                >
                  <input
                    type="radio"
                    name="selectRadio"
                    value={index}
                    checked={index === selectListIndex}
                    readOnly
                  />
                  <div className={cn('select-card-content')}>
                    <div>{item.universityName}</div>
                    <div>
                      {item.collegeDepartmentName} | {item.collegeAdmissionName}
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
      )}
      {step === 3 && (
        <div className={cn('done')}>
          <div className={cn('icon')}>
            <img src={getUrlPath(`radio_check.png`)} alt="radio_check" />
          </div>
          <div className={cn('done-title')}>면접 예상 질문 접수 완료</div>
          <div className={cn('done-subtitle')}>
            꼼꼼하게 분석하여 질문을 생성하겠습니다.
            <br />
            답변 완료 시 카카오톡 알림으로 즉시 알려드려요.
          </div>
        </div>
      )}
      {(step === 1 || step === 2) && (
        <div className={cn('button-group')}>
          <button
            className={cn('back-button')}
            onClick={() => setStep(step - 1)}
          >
            이전
          </button>
          <button
            className={cn('submit-button')}
            onClick={() => {
              // nextButton 메소드만들기
              clickNextButton(step);
            }}
          >
            다음
          </button>
        </div>
      )}
      {step === 3 && (
        <div className={cn('button-group')}>
          <button
            className={cn('submit-button')}
            onClick={() => {
              // nextButton 메소드만들기
              clickNextButton(step);
            }}
          >
            접수 내역 확인
          </button>
        </div>
      )}
      <Popup
        isOpen={done}
        onClose={() => setDone(false)}
        backgroundClickClose={false}
        isCloseButton={false}
        width="max-w-[453px]"
      >
        <div className={cn('popup-container')}>
          <img
            src={getUrlPath(`admin_notice_icon.png`)}
            alt={`admin_notice_icon`}
            className={cn('admin_notice_icon')}
          />
          <div className={cn('popup-title')}>면접 예상 질문 접수 확인</div>
          <div className={cn('popup-subtitle')}>
            아래 정보로 면접 예상 질문을 생성하시겠어요?
          </div>
          <div className={cn('user-info')}>
            <div>
              {userData[0].studentName} | {userData[0].eduSchoolNmKr} |{' '}
              {getCalculateGrade(userData[0].studentEntryYear, false)}
            </div>
            <div className={cn('sub-content')}>
              {response ? response[selectListIndex].universityName : ''}
              {response ? response[selectListIndex].collegeDepartmentName : ''}
              {response ? response[selectListIndex].collegeAdmissionName : ''}
            </div>
          </div>
          <div className={cn('popup-button-group')}>
            <button
              className={cn('popup-back-button')}
              onClick={() => {
                setDone(false);
              }}
            >
              이전
            </button>
            <button
              className={cn('popup-submit-button')}
              onClick={() => {
                predictQuestions
                  .updateCollegesApply({
                    studentRecordIdx:
                      response!![selectListIndex].studentRecordIdx,
                    applyCollegesAdmissionsIdx: response!![selectListIndex].idx,
                  })
                  .then(response => {
                    if (response.code === '2000') {
                      setStep(step + 1);
                    }
                    setDone(false);
                  });
              }}
            >
              접수 완료
            </button>
          </div>
        </div>
      </Popup>
      <Popup
        isOpen={errorAlert}
        onClose={() => setErrorAlert(false)}
        backgroundClickClose={true}
        isCloseButton={false}
        width="max-w-[453px]"
      >
        <div className={cn('error-popup-container')}>
          <div className={cn('error-popup-title')}>{errorAlertMsg.title}</div>
          <div className={cn('error-popup-subtitle')}>
            {errorAlertMsg.subtitle}
          </div>
          <div className={cn('popup-button-group')}>
            <button
              className={cn('popup-button')}
              onClick={() => {
                if (errorAlertMsg.title.includes('잘못된'))
                  window.location.reload();
                setErrorAlert(false);
              }}
            >
              확인
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default RequestForm;
