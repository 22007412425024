import React from 'react';
import styles from '../Result.module.scss';
import { getCalculateGrade, getUrlPath } from '../../../../utils';
import classNames from 'classnames/bind';
import useCardItem from './hooks/useCardItem';
import {
  GetCollegeAdmissionApplicationResponse,
  GetRecordQuestionAnalyzeResponse,
  RecordQuestionsGeneratedRequest,
} from '../../../../types/record-question-analyze/api';
import dayjs from 'dayjs';

const cn = classNames.bind(styles);

export interface CardItemProps {
  studentRecordAnalyzeStatus: GetRecordQuestionAnalyzeResponse;
  appliedCollegeAdmission: GetCollegeAdmissionApplicationResponse;
}

const CardItem = ({
  studentRecordAnalyzeStatus,
  appliedCollegeAdmission,
  setResult,
  setGeneratedParam,
}: CardItemProps & {
  setResult: React.Dispatch<React.SetStateAction<boolean>>;
  setGeneratedParam: React.Dispatch<
    React.SetStateAction<RecordQuestionsGeneratedRequest>
  >;
}) => {
  const { handleGotoResultDetail } = useCardItem({
    setResult,
    setGeneratedParam,
  });

  return (
    <div
      className={cn('card')}
      onClick={() =>
        handleGotoResultDetail(
          studentRecordAnalyzeStatus.analyzeStatusType,
          appliedCollegeAdmission.studentRecordIdx,
          appliedCollegeAdmission.studentRecordAnalyzeIdx,
        )
      }
    >
      <div className={cn('card-content')}>
        {studentRecordAnalyzeStatus.recordCreateAt && (
          <div className={cn('card-header')}>
            {dayjs(studentRecordAnalyzeStatus.recordCreateAt).format(
              'YYYY.MM.DD',
            )}
          </div>
        )}

        <div className={cn('card-title')}>
          {studentRecordAnalyzeStatus.eduSchoolNmKr} |{' '}
          {studentRecordAnalyzeStatus.studentName} |{' '}
          {getCalculateGrade(studentRecordAnalyzeStatus.studentEntryYear)}
        </div>
        <div className={cn('card-subtitle')}>
          {appliedCollegeAdmission.universityName} |{' '}
          {appliedCollegeAdmission.collegeDepartmentName} |{' '}
          {appliedCollegeAdmission.collegeAdmissionName}
        </div>
      </div>
      <div className={cn('card-actions')}>
        <button
          className={cn('action-button', {
            completed: studentRecordAnalyzeStatus.analyzeStatusType === 'DONE',
          })}
        >
          {studentRecordAnalyzeStatus.analyzeStatusType === 'DONE'
            ? '답변완료'
            : '대기중'}
        </button>
        <img
          src={getUrlPath(`right_small.png`)}
          alt="right_small"
          className={cn('arrow-icon')}
        />
      </div>
    </div>
  );
};

export default CardItem;
