import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { Modal } from "../components";

export interface ConfirmData {
  title: string;
  subtitle?: string;
  confirmButtonName?: string;
  closeButtonName?: string;
  onConfirm: () => void;
  onClose?: () => void; // onClose 콜백을 추가
  zIndex?: number;
}

interface ConfirmContextType {
  confirm: ({
    title,
    subtitle,
    onConfirm,
    onClose,
    confirmButtonName,
    closeButtonName,
  }: ConfirmData) => void;
}

const ConfirmContext = createContext<ConfirmContextType | undefined>(undefined);

export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error("useConfirm must be used within a ConfirmProvider");
  }
  return context;
};

interface ConfirmProviderProps {
  children: ReactNode;
}

export const ConfirmProvider: React.FC<ConfirmProviderProps> = ({
  children,
}) => {
  const [confirms, setConfirms] = useState<ConfirmData[]>([]);

  const confirm = useCallback(
    ({
      title,
      subtitle,
      onConfirm,
      onClose, // onClose 콜백을 추가
      confirmButtonName = "확인",
      closeButtonName = "취소",
      zIndex,
    }: ConfirmData) => {
      setConfirms((prevConfirms) => {
        const zIndexNumber = zIndex || 99 + prevConfirms.length * 10;
        return [
          ...prevConfirms,
          {
            title,
            subtitle,
            onConfirm,
            onClose,
            zIndexNumber,
            confirmButtonName,
            closeButtonName,
          },
        ];
      });
    },
    []
  );

  const resolveConfirm = useCallback((confirmToRemove: ConfirmData) => {
    // confirmToRemove?.onClose && confirmToRemove.onClose();
    setConfirms((prevConfirms) =>
      prevConfirms.filter((confirm) => confirm !== confirmToRemove)
    );
  }, []);

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      {confirms.map((confirmData, index) => (
        <Modal
          key={index}
          title={confirmData.title}
          subtitle={confirmData.subtitle}
          onClose={() => {
            confirmData?.onClose && confirmData.onClose();
            resolveConfirm(confirmData);
          }}
          onConfirm={() => {
            confirmData.onConfirm();
            resolveConfirm(confirmData); // onConfirm 콜백 실행 후 모달 닫기
          }}
          zIndex={confirmData.zIndex}
          confirmButtonName={confirmData.confirmButtonName}
          closeButtonName={confirmData.closeButtonName}
        />
      ))}
    </ConfirmContext.Provider>
  );
};
