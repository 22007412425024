import React from 'react';
import styles from './List.module.scss'; // SCSS 파일
import { getUrlPath } from '../../../utils';
import useList from './hooks/useList';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';

const cn = classNames.bind(styles);

const List = () => {
  const {
    handleGoToDetail,
    items,
    handlePageChange,
    currentPage,
    totalPage,
    handleBlur,
    handleInputChange,
    searchTerm,
  } = useList();

  return (
    <div className={cn('container')}>
      <div className={cn('header')}>
        <h1 className={cn('title')}>알림사항</h1>
        <div className={cn('search-bar')}>
          <img
            className={`${cn('search-icon')}`}
            src={getUrlPath(`search_icon.png`)}
            alt="search_icon"
          />
          <input
            type="text"
            placeholder="검색 키워드를 2글자 이상 입력해주세요."
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleBlur} // Enter 키 감지
          />
        </div>
      </div>

      {/* 리스트 */}
      {items.length === 0 ? (
        <div className={cn('no-data')}>
          <div className={cn('icon')}>
            <img src={getUrlPath('empty_box.png')} alt="No Results" />
          </div>
          <p className={cn('message')}>조회 된 데이터가 없어요.</p>
        </div>
      ) : (
        <>
          <div className={cn('list')}>
            {items.map(item => (
              <div
                className={cn('list-item')}
                key={item.boardPostIdx}
                onClick={() => handleGoToDetail(item.boardPostIdx)}
              >
                <div className={cn('left')}>
                  <div className={cn('badge-container')}>
                    <div className={cn('badge')}>{item.boardCategoryName}</div>
                  </div>
                  <div className={cn('content')}>
                    <p className={cn('message', { bold: item.postTopSort })}>
                      {item.postTitle}
                    </p>
                    <p className={cn('date')}>
                      {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}
                    </p>
                  </div>
                </div>
                <span className={cn('arrow')}>
                  <img
                    className={cn('arrow-text-icon')}
                    src={getUrlPath('right_small.png')}
                    alt="right_small"
                  />
                </span>
              </div>
            ))}
          </div>

          {/* 페이지네이션 */}
          <div className={cn('pagination')}>
            {currentPage !== 1 && (
              <button
                className={cn('page-button')}
                onClick={() => handlePageChange('prev')}
              >
                &lt;
              </button>
            )}

            {totalPage > currentPage && (
              <button
                className={cn('page-button')}
                onClick={() => handlePageChange('next')}
              >
                &gt;
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default List;
