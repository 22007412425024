import React from 'react';
import classNames from 'classnames/bind';
import styles from './Info.module.scss';
import { Card, Popup } from '../../../components';
import useInfo from './hooks/useInfo';
import { getUrlPath } from '../../../utils';

const cn = classNames.bind(styles);

const Info = () => {
  const {
    handleGoToSecession,
    handleToggle,
    isNotificationEnabled,
    email,
    name,
    phone,
    isPrivacyConsentOpen,
    isCallApi,
    notificationDate,
    handlePrivacyConsentClose,
  } = useInfo();

  if (!isCallApi) {
    return <></>;
  }

  return (
    <div className={cn('container')}>
      <div className={cn('card')}>
        <h1 className={cn('title')}>
          회원정보
          <div className={cn('kakao-connect')}>
            카카오톡 연결 계정
            <img src={getUrlPath('kakao_icon.png')} alt="카카오톡" />
          </div>
        </h1>

        <div className={cn('member-info')}>
          <label className={cn('member-info__label')}>이름</label>
          <input
            className={cn('member-info__input')}
            type="text"
            value={name} // 이름 상태값 사용
            readOnly
          />
          <label className={cn('member-info__label')}>휴대폰</label>
          <input
            className={cn('member-info__input')}
            type="text"
            value={phone} // 휴대폰 상태값 사용
            readOnly
          />
          <label className={cn('member-info__label')}>이메일</label>
          <input
            className={cn('member-info__email')}
            type="text"
            value={email} // 이메일 상태값 사용
            readOnly
          />
        </div>
      </div>

      <div className={cn('diver')} />

      <div className={cn('card')}>
        <div className={cn('alert-settings__header')}>
          <span className={cn('title')}>알림설정</span>
          <label className={cn('alert-settings__toggle-container')}>
            <input
              type="checkbox"
              className={cn('alert-settings__toggle')}
              checked={isNotificationEnabled}
              onChange={handleToggle}
            />
          </label>
        </div>
        <div className={cn('alert-settings')}>
          <Card
            title="혜택정보"
            subtitle="고객 혜택 제공을 위한 개인정보 이용동의"
            onClick={handlePrivacyConsentClose}
            moreText="전체보기"
          />
          {notificationDate && (
            <p className={cn('alert-settings__footer')}>
              최종 변경일: {notificationDate}
            </p>
          )}
        </div>
      </div>

      {/* 회원탈퇴 버튼을 오른쪽에 배치하기 위한 컨테이너 */}
      <div className={cn('withdraw-container')}>
        <button className={cn('withdraw-button')} onClick={handleGoToSecession}>
          회원탈퇴
        </button>
      </div>

      <Popup
        isOpen={isPrivacyConsentOpen}
        onClose={handlePrivacyConsentClose}
        backgroundClickClose={false}
        isCloseButton={false}
        width="w-[335px] md:w-[453px] lg:w-[800px]"
      >
        <div className={cn('popupContainer')}>
          <div className={cn('popupTitle')}>
            [필수] 개인정보 수집 및 이용 동의
          </div>
          <div className={cn('popupText')}>
            수시탐탐은 &quot;생기부 분석&quot; 서비스를 위하여 생활기록부에
            포함되는 개인정보의 수집 및 이용목적, 보유기간, 동의 거부권 등에
            관한 사항을 안내드리오니, 자세히 읽은 후 동의하여 주시기 바랍니다.
          </div>
          <div className={cn('popupList')}>
            <div className={cn('listTitle')}>수집 및 이용 목적</div>
            <div className={cn('listItem')}>생기부 분석</div>

            <div className={cn('listTitle')}>수집항목</div>
            <div className={cn('listItem')}>
              [필수] 학생 성명, 학교, 반, 번호
            </div>
            <div className={cn('listItem')}>
              주민등록번호, 주소, 성별, 가족사항, 담당성명은 수집되지 않습니다.
            </div>

            <div className={cn('listTitle')}>보유기간</div>
            <div className={cn('listItem')}>
              회원탈퇴 시, 삭제 요청 시, 법정 의무 보유 기간
            </div>

            <div className={cn('popupText')}>
              회원은 위 개인정보의 수집에 동의하지 않을 수 있습니다. 다만 생기부
              분석 서비스는 이용하실 수 없습니다. 위 개인정보 수집 및 이용에
              동의하지 않는 경우 다른 서비스의 이용에는 지장이 없습니다.
            </div>
          </div>
        </div>
        <div className={cn('popupFooter')} onClick={handlePrivacyConsentClose}>
          <button>확인</button>
        </div>
      </Popup>
    </div>
  );
};

export default Info;
