import React, { Fragment } from 'react';
import styles from '../Write.module.scss';
import classNames from 'classnames/bind';
import { getCalculateGrade, getUrlPath } from '../../../../utils';
import useFileUploadCard from './hooks/useFileUploadCard';
import { StudentRecord } from '../../../../types/recode/api';
import dayjs from 'dayjs';

const cn = classNames.bind(styles);

export interface FileUploadCardProps {
  setDetailId: React.Dispatch<React.SetStateAction<number>>;
  setInit: React.Dispatch<React.SetStateAction<boolean>>;
  list: StudentRecord[];
  getRecodeList: () => void;
}

const FileUploadCard = ({
  setDetailId,
  setInit,
  list,
  getRecodeList,
}: FileUploadCardProps) => {
  const {
    handleGoToLifeRecodeUpload,
    handleGoToInterviewQuestion,
    handleDeleteConfirm,
    handleGoToRecodeForm,
  } = useFileUploadCard({ setDetailId, setInit, list, getRecodeList });

  return (
    <>
      <div className={cn('admin-message-container')}>
        {/* 아이콘 영역 */}
        <div className={cn('icon')}>
          <img
            src={getUrlPath('information_icon.png')}
            alt="information_icon"
          />
        </div>
        {/* 메시지 영역 */}
        <div className={cn('message')}>
          <p>
            생기부를 업로드 해주세요. ‘생활기록부’를 업로드 후 면접 예상 질문
            신청이 가능해요.
          </p>
        </div>
      </div>

      <div className={cn('upload-section-wrapper')}>
        {list.map(item => {
          return (
            <Fragment key={item.studentRecordIdx}>
              <div className={cn('upload-section')}>
                <div className={cn('header')}>
                  <div
                    className={cn('header-title', {
                      'file-yn': item.isFileUse,
                    })}
                  >
                    {item.analyzeStatusType === 'NOT_YET' && (
                      <button
                        className={cn('close-button')}
                        onClick={() =>
                          handleDeleteConfirm(item.studentRecordIdx)
                        }
                      >
                        <img
                          src={getUrlPath(`delete_icon.png`)}
                          alt="close_icon"
                        />
                      </button>
                    )}
                    <span>
                      {item.studentName} | {item.eduSchoolNmKr} |{' '}
                      {getCalculateGrade(item.studentEntryYear)}
                    </span>
                  </div>

                  {/* 수정, 업로드 버튼 */}
                  {item.analyzeStatusType === 'NOT_YET' && (
                    <div className={cn('actions-pc')}>
                      <button
                        className={cn('edit-button')}
                        onClick={() =>
                          handleGoToRecodeForm(item.studentRecordIdx)
                        }
                      >
                        수정
                      </button>
                      <button
                        className={cn('upload-button')}
                        onClick={() =>
                          handleGoToLifeRecodeUpload(
                            item.isFileUse,
                            item.studentRecordIdx,
                          )
                        }
                      >
                        {item.isFileUse ? '생기부 재 업로드' : '생기부 업로드'}
                      </button>
                    </div>
                  )}
                </div>

                {/* 파일 정보 */}
                {item.isFileUse && (
                  <div className={cn('file-info')}>
                    <div className={cn('file-meta')}>
                      <img src={getUrlPath('file_icon.png')} alt="file_icon" />
                      <span className={cn('file-date')}>
                        {dayjs(item.fileCreatedAt).format('YYYY.MM.DD')}
                      </span>
                      <span className={cn('file-name')}>
                        {item.fileOriginName}
                      </span>
                    </div>
                  </div>
                )}

                {/* 수정, 업로드 버튼 */}
                {item.analyzeStatusType === 'NOT_YET' && (
                  <div className={cn('flex-display')}>
                    <div className={cn('actions-mo')}>
                      <button
                        className={cn('edit-button')}
                        onClick={() =>
                          handleGoToRecodeForm(item.studentRecordIdx)
                        }
                      >
                        수정
                      </button>
                      <button
                        className={cn('upload-button')}
                        onClick={() =>
                          handleGoToLifeRecodeUpload(
                            item.isFileUse,
                            item.studentRecordIdx,
                          )
                        }
                      >
                        {item.isFileUse ? '생기부 재 업로드' : '생기부 업로드'}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Fragment>
          );
        })}
      </div>

      {list[0].isFileUse && (
        <div className={cn('apply-button-container')}>
          <button
            className={cn('apply-button')}
            onClick={handleGoToInterviewQuestion}
          >
            면접 예상 질문 신청
          </button>
        </div>
      )}
    </>
  );
};

export default FileUploadCard;
