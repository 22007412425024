import React from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useKakaoLogin from '../../hooks/useKakaoLogin';

const Oauth = () => {
  const [searchParams] = useSearchParams();
  const { getKakaoToken } = useKakaoLogin();

  useEffect(() => {
    window.setTimeout(
      () => getKakaoToken(searchParams.get('code') as string),
      500,
    );
  }, [getKakaoToken, searchParams]);

  return <></>;
};

export default Oauth;
