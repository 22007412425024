import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import kakaoApi from '../api/kakao';
import { useAlert, useAuth } from '../contexts';
import { KakaoProfiles } from '../types/kakao/api';
import { setItem } from '../utils/sessionStorage';
import { SESSION_STORAGE } from '../constants';
import authApi from '../api/auth';

const useKakaoLogin = () => {
  const navigate = useNavigate();
  const { login: authLogin } = useAuth();
  const { alert } = useAlert();
  const location = useLocation();
  const apiCall = useRef({
    member: false,
  });
  // 로그인 성공 후 리디렉션할 경로
  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.7.2/kakao.min.js';
    script.async = true;
    script.onload = () => {
      if (window?.Kakao) {
        console.log('Kakao SDK initialized');
        window.Kakao.init(process.env.REACT_APP_KAKAO_INIT || '');
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const login = useCallback(async () => {
    if (window?.Kakao) {
      window.Kakao.API.request({
        url: '/v1/user/unlink',
      })
        .then(function () {})
        .catch(function (error: any) {
          console.log(error);
        })
        .finally(() => {
          window.Kakao.Auth.authorize({
            redirectUri: process.env.REACT_APP_KAKAO_REDIREACT_URL,
          });
        });
    }
  }, []);

  const getUserData = useCallback((id: number, email: string) => {
    return authApi.login({
      accountId: email,
      providerType: 'kakao',
      providerUID: `${id}`,
    });
  }, []);

  const setLoginToken = useCallback((accessToken = '', refreshToken = '') => {
    setItem(
      `${SESSION_STORAGE.init}${SESSION_STORAGE.loginToken}`,
      accessToken,
    );

    setItem(
      `${SESSION_STORAGE.init}${SESSION_STORAGE.refreshToken}`,
      refreshToken,
    );

    authLogin();
    navigate('/');
  }, []);

  const getKakaoToken = useCallback(
    async (code: string) => {
      try {
        if (window?.Kakao && !apiCall.current.member) {
          apiCall.current.member = true;

          const kakaoToken = await kakaoApi.authToken(code);

          window.Kakao.Auth.setAccessToken(kakaoToken?.data?.accessToken || '');

          const kakaoUser: KakaoProfiles = await window.Kakao.API.request({
            url: '/v2/user/me',
          });

          const kakaoChannel = await kakaoApi.channelsYn(
            kakaoToken?.data?.accessToken || '',
          );

          if (kakaoUser?.id) {
            // 로그인 API 우선 호출 -> 회원이 아니라면
            const response = await getUserData(
              kakaoUser.id,
              kakaoUser.kakao_account.email,
            );

            if (response.code !== '2000') {
              const {
                email,
                name,
                birthyear,
                phone_number,
                profile,
                is_email_valid,
                is_email_verified,
              } = kakaoUser?.kakao_account;

              const memberJoin = await authApi.signUp({
                accountId: email,
                providerUid: `${kakaoUser.id}`,
                accountRoleType: 'USER_EASY',
                emailValid: is_email_valid,
                emailVerified: is_email_verified,
                infoName: name,
                infoDateBirth: birthyear,
                infoPhone: phone_number,
                infoNickname: profile?.nickname || '',
                infoEmail: email,
                isNotify: kakaoChannel === 'ADDED',
              });

              if (memberJoin.code !== '2000') {
                alert({
                  title: '로그인 실패',
                });
              } else {
                const response = await getUserData(kakaoUser.id, email);
                setLoginToken(
                  response.data?.accessToken,
                  response.data?.refreshToken,
                );
              }
            } else {
              setLoginToken(
                response.data?.accessToken,
                response.data?.refreshToken,
              );
            }
            // 로그인 성공 후 원래 가려던 페이지로 리디렉션
            navigate(from, { replace: true });
          } else {
            navigate('/');
          }

          apiCall.current.member = false;
        }
      } catch (error) {
        console.log(error);
        navigate('/');
        apiCall.current.member = false;
      }
    },
    [authLogin, from, navigate],
  );

  return { login, getKakaoToken };
};

export default useKakaoLogin;
