import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAlert } from '../../../../../contexts';
import dayjs from 'dayjs';
import recodeApi from '../../../../../api/recode';
import { toNumber } from 'lodash-es';
import { getCalculateGrade } from '../../../../../utils';
import commonApi from '../../../../../api/common';
import { SchoolSearchResponse } from '../../../../../types/common/api';

export interface UseRecodeForm {
  detailNo: number;
  setInit: React.Dispatch<React.SetStateAction<boolean>>;
  getRecodeList: () => void;
}

const useRecodeRecode = ({
  detailNo,
  setInit,
  getRecodeList,
}: UseRecodeForm) => {
  const [name, setName] = useState<string>(''); // 이름 상태 추가
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedGrade, setSelectedGrade] = useState<number | null>(null);
  const [isSchoolSearch, setIsSchoolSearch] = useState(false);
  const [result, setResults] = useState<SchoolSearchResponse[]>([]);
  const [search, setSearch] = useState<string>('');
  const [idx, setSearchIdx] = useState<number | null>(null);
  const [schoolType, setSchoolType] = useState<string>(''); // 학교유형 상태 추가
  const { alert } = useAlert();

  // 외부 클릭 감지 Ref 설정
  const yearDropdownRef = useRef<HTMLDivElement>(null);
  const schoolDropdownRef = useRef<HTMLDivElement>(null);

  const [yearOptions, setYearOption] = useState<string[] | null>(null);

  // 학년 데이터
  const grades = useMemo(
    () => [
      { label: '1학년', value: 1 },
      { label: '2학년', value: 2 },
      { label: '3학년', value: 3 },
      { label: '졸업생', value: 0 },
    ],
    [],
  );

  const generateYearRange = (): string[] => {
    const currentYear = dayjs().year(); // 현재 년도 가져오기
    const startYear = currentYear - 3; // 현재 년도에서 3년 전 계산

    // 7개의 연도를 배열로 반환
    const years = [];
    for (let year = startYear; year > startYear - 10; year--) {
      years.push(year.toString());
    }

    return years;
  };

  const handleYearSearch = () => {
    // 검색 결과를 모든 고등학교 리스트로 세팅
    if (selectedGrade || selectedGrade === null) {
      return;
    }

    setYearOption(yearOptions?.length ? null : generateYearRange());
  };

  // 연도 계산 함수
  const calculateYear = (grade: number) => {
    if (!grade) {
      setSelectedYear('');
      return;
    }

    const currentYear = dayjs().year(); // 현재 년도
    const year = currentYear - grade + 1;

    setSelectedYear(year.toString());
  };

  // 학년 선택 핸들러
  const handleGradeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = parseInt(event.target.value, 10); // 학년 값 가져오기
    setSelectedGrade(selectedValue);
    calculateYear(selectedValue); // 학년을 기반으로 연도 계산
  };

  const getRecodeDetailApi = useCallback(async () => {
    const response = await recodeApi.getRecode(detailNo);

    if (response.code === '2000' && response.data) {
      const {
        studentName,
        schoolsIdx,
        studentEntryYear,
        eduSchoolNmKr,
        separation,
      } = response.data;

      setName(studentName);
      setSelectedGrade(getCalculateGrade(studentEntryYear, true) as number);
      setSelectedYear(`${studentEntryYear}`);
      setSearch(eduSchoolNmKr);
      setSearchIdx(schoolsIdx);
      setSchoolType(separation);
    }
  }, [detailNo]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // 입학년도 드롭다운 외부 클릭 시 초기화
      if (
        yearDropdownRef.current &&
        !yearDropdownRef.current.contains(event.target as Node)
      ) {
        setYearOption([]); // 입학년도 드롭다운 초기화
      }

      // 학교 검색 드롭다운 외부 클릭 시 초기화
      if (
        schoolDropdownRef.current &&
        !schoolDropdownRef.current.contains(event.target as Node)
      ) {
        setIsSchoolSearch(false); // 학교 검색 드롭다운 초기화
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setResults]);

  // 컴포넌트가 처음 렌더링될 때 전체 학교 데이터를 세팅
  useEffect(() => {
    getRecodeDetailApi().then();
  }, [detailNo]);

  // 등록 버튼 클릭 시 필수값 확인 로직
  const handleSubmit = useCallback(async () => {
    if (!name) {
      alert({ title: '이름을 입력해 주세요.' });
      return;
    }
    if (selectedGrade === null) {
      alert({ title: '현재 학년을 선택해 주세요.' });
      return;
    }
    if (!selectedYear) {
      alert({ title: '입학년도를 선택해 주세요.' });
      return;
    }

    if (!search || search.length < 2) {
      alert({ title: '고등학교 이름을 2글자 이상 입력해 주세요.' });
      return;
    }

    const recordData = {
      nowGrade: selectedGrade === 0 ? 9 : selectedGrade,
      schoolsIdx: idx as number,
      studentEntryYear: toNumber(selectedYear),
      studentName: name,
    };

    const response = detailNo
      ? await recodeApi.updateRecode(recordData, detailNo)
      : await recodeApi.createRecode(recordData);

    if (response.code === '2000') {
      // 리스트 불러오는 api 호출
      getRecodeList();
    }
  }, [alert, name, search, result, selectedGrade, selectedYear, idx]);

  const handleSchoolSearch = useCallback(async () => {
    if (isSchoolSearch && result.length) {
      setIsSchoolSearch(false);
      return;
    }

    if (!search || search.length < 2) {
      alert({ title: '고등학교 이름을 2글자 이상 입력해 주세요.' });
      return;
    }

    const response = await commonApi.getHighSchoolList({ searchName: search });

    if (response.code === '2000') {
      setIsSchoolSearch(true);
      if (response?.data) {
        setResults(response.data);
      } else {
        setResults([]);
      }
    }
  }, [search, isSchoolSearch, result]);

  const handleSchoolChange = useCallback((value: string) => {
    setSearch(value);
  }, []);

  return {
    detailNo,
    setInit,
    idx,
    setSearchIdx,
    search,
    handleSchoolChange,
    handleSchoolSearch,
    handleSubmit,
    handleGradeChange,
    handleYearSearch,
    grades,
    yearOptions,
    setName,
    name,
    isSchoolSearch,
    schoolType,
    setSchoolType,
    setIsSchoolSearch,
    result,
    selectedYear,
    setSelectedYear,
    yearDropdownRef,
    schoolDropdownRef,
    setYearOption,
    selectedGrade,
  };
};

export default useRecodeRecode;
