import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getItem } from '../../utils/sessionStorage';
import { SESSION_STORAGE } from '../../constants';

const PrivateRoute = () => {
  const location = useLocation(); // 현재 위치(사용자가 이동하려고 했던 경로)를 가져옴

  const isLogin = !!getItem(
    `${SESSION_STORAGE.init}${SESSION_STORAGE.loginToken}`,
  );

  return isLogin ? (
    <Outlet />
  ) : (
    // 로그인되지 않은 상태면 로그인 페이지로 리디렉트, 그리고 state에 원래 경로 저장
    <Navigate to="/member/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
