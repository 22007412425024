import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { Modal } from "../components";

export interface AlertData {
  title: string;
  subtitle?: string;
  buttonName?: string;
  zIndex?: number; // zIndex를 추가합니다.
  onClose?: () => void;
}

interface AlertContextType {
  alert: ({ title, subtitle, buttonName, onClose }: AlertData) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider = ({ children }: AlertProviderProps) => {
  const [alerts, setAlerts] = useState<AlertData[]>([]);

  const alert = useCallback(
    ({ title, subtitle, buttonName = "확인", zIndex }: AlertData) => {
      setAlerts((prevAlerts) => {
        const zIndexNumber = zIndex || 99 + prevAlerts.length * 10;
        return [
          ...prevAlerts,
          { title, subtitle, zIndexNumber, buttonName }, // z-index 값을 추가
        ];
      });
    },
    []
  );

  const resolveAlert = useCallback((alertToRemove: AlertData) => {
    alertToRemove?.onClose && alertToRemove.onClose();
    setAlerts((prevAlerts) =>
      prevAlerts.filter((alert) => alert !== alertToRemove)
    );
  }, []);

  return (
    <AlertContext.Provider value={{ alert }}>
      {children}
      {alerts.map((alertData, index) => (
        <Modal
          key={index}
          title={alertData.title}
          subtitle={alertData.subtitle}
          onConfirm={() => resolveAlert(alertData)}
          zIndex={alertData.zIndex} // z-index를 전달
          confirmButtonName={alertData.buttonName}
        />
      ))}
    </AlertContext.Provider>
  );
};
