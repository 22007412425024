import React, { createContext, useContext, useState, useCallback } from 'react';
import { Toast } from '../components';

export interface ToastContextType {
  toast: ({
    message,
    duration,
    tag,
  }: {
    message: string;
    duration?: number;
    tag?: string;
  }) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastTag, setToastTag] = useState<string | undefined>();
  const [duration, setDuration] = useState<number>(3000);

  const toast = useCallback(
    ({
      message,
      duration = 3000,
      tag,
    }: {
      message: string;
      duration?: number;
      tag?: string;
    }) => {
      setToastMessage(message);
      setDuration(duration);
      setToastTag(tag);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setToastMessage(null);
  }, []);

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      {toastMessage && (
        <Toast
          message={toastMessage}
          duration={duration}
          onClose={handleClose}
          tag={toastTag}
        />
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
