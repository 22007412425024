import { get, post, put } from '../../utils';
import {
  easySignInResponse,
  easySignRefreshRequest,
  easySignRequest,
  easySignUpRequest,
  easySignUpResponse,
  memberInfoLeaveRequest,
  memberInfoNotifyResponse,
  memberInfoResponse,
} from '../../types/auth/api';
import { Response } from '../../types';

export class AuthApi {
  login = async (data: easySignRequest) => {
    const response: Response<easySignInResponse> = await post(
      '/api/auth/easySign/in',
      data,
    );

    return response as Response<easySignInResponse>;
  };

  refresh = async (data: easySignRefreshRequest) => {
    const response: Response<easySignInResponse> = await post(
      '/api/auth/token/refresh',
      data,
    );

    return response as Response<easySignInResponse>;
  };

  signUp = async (data: easySignUpRequest) => {
    const response: Response<easySignUpResponse> = await post(
      '/api/account/easy-signup',
      data,
    );

    return response as Response<easySignUpResponse>;
  };

  leave = async (data: memberInfoLeaveRequest) => {
    const response: Response<easySignUpResponse> = await put(
      '/api/account/leave',
      data,
    );

    return response as Response<easySignUpResponse>;
  };

  info = async () => {
    const response: Response<memberInfoResponse> =
      await get('/api/account/info');

    return response as Response<memberInfoResponse>;
  };

  notify = async () => {
    const response: Response<memberInfoNotifyResponse> = await put(
      '/api/account/notify',
      '',
    );

    return response as Response<memberInfoNotifyResponse>;
  };
}

const authApi = new AuthApi();
export default authApi;
