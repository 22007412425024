export const LOCAL_STORAGE = {
  init: 'funble_local_storage',
};

export const SESSION_STORAGE = {
  init: 'funble_session_storage',
  noticePage: '_notice_page',
  loginToken: '_login_token',
  refreshToken: '_login_refresh_token',
};

export const COOKIE = {
  init: 'funble_cookie_',
};

// 파일 용량 제한 (예: 10MB)
export const MAX_FILE_SIZE = 50 * 1024 * 1024; // 10MB

export const ALLOWED_FILE_TYPES = ['application/pdf', 'text/html']; // PDF 또는 HTML만 허용
