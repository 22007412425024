import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './Toast.module.scss';
import classNames from 'classnames/bind';

interface ToastProps {
  message: string;
  duration?: number; // 지속 시간, 기본값은 3초
  onClose: () => void;
  tag?: string;
}

const cn = classNames.bind(styles);

const Toast = ({
  message,
  duration = 3000,
  onClose,
  tag = 'body',
}: ToastProps) => {
  useEffect(() => {
    const timer = setTimeout(onClose, duration);
    return () => clearTimeout(timer); // 컴포넌트가 언마운트될 때 타이머를 제거
  }, [duration, onClose]);

  return ReactDOM.createPortal(
    <div
      className={cn('toast', {
        show: !!message.length,
      })}
    >
      {message}
    </div>,
    document.getElementById(tag) || document.body, // body에 직접 렌더링
  );
};

export default Toast;
