import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts';

const useHeader = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownProfileOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // 드롭다운을 감싸는 요소 참조
  const profileDropdownRef = useRef<HTMLDivElement>(null); // 프로필 드롭다운 참조

  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const toggleProfileDropdown = useCallback(() => {
    setIsDropdownOpen(!isDropdownProfileOpen);
    setDropdownOpen(false);
  }, [isDropdownProfileOpen]);

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuOpen(!mobileMenuOpen);
  }, [mobileMenuOpen]);

  const toggleDropdown = useCallback(
    (type?: string) => {
      setDropdownOpen(type === 'leave' ? false : !dropdownOpen);
      setIsDropdownOpen(false);
    },
    [dropdownOpen],
  );

  const handleGoToMain = useCallback(() => {
    // pc 전용 메인가기
    navigate('/');
    setDropdownOpen(false);
    setIsDropdownOpen(false);
  }, [navigate]);

  const handleGoToMemberInfo = useCallback(() => {
    navigate('/member/info');
    // 모바일 전용
    if (window.innerWidth <= 800) {
      setMobileMenuOpen(false);
    }
    setDropdownOpen(false);
    setIsDropdownOpen(false);
  }, [navigate]);

  const handleGoToInstructions = useCallback(() => {
    navigate('/instructions');
    // 모바일 전용
    if (window.innerWidth <= 800) {
      setMobileMenuOpen(false);
    }
    setDropdownOpen(false);
    setIsDropdownOpen(false);
  }, [navigate]);

  const handleGoToNoticeList = useCallback(() => {
    navigate('/notice/list');
    // 모바일 전용
    if (window.innerWidth <= 800) {
      setMobileMenuOpen(false);
    }
    setDropdownOpen(false);
    setIsDropdownOpen(false);
  }, [navigate]);

  const handleGoToInterviewResult = useCallback(() => {
    navigate('/interview/result');
    // 모바일 전용
    if (window.innerWidth <= 800) {
      setMobileMenuOpen(false);
    }
    setDropdownOpen(false);
    setIsDropdownOpen(false);
  }, [navigate]);

  const handleGoToInterviewQuestion = useCallback(() => {
    navigate('/interview/question');

    // 모바일 전용
    if (window.innerWidth <= 800) {
      setMobileMenuOpen(false);
    }
    setDropdownOpen(false);
    setIsDropdownOpen(false);
  }, [navigate]);

  const handleGoToLifeRecodeWrite = useCallback(() => {
    navigate('/liferecode/write');
    // 모바일 전용
    if (window.innerWidth <= 800) {
      setMobileMenuOpen(false);
    }
    setDropdownOpen(false);
    setIsDropdownOpen(false);
  }, [navigate]);

  const handleGoToMemberLogin = useCallback(() => {
    navigate('/member/login');
    setDropdownOpen(false);
    setIsDropdownOpen(false);
  }, [navigate]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      profileDropdownRef.current &&
      !profileDropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false); // profileDropdownRef 외부 클릭 시 드롭다운을 닫음
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return {
    handleGoToMemberInfo,
    handleGoToInstructions,
    handleGoToNoticeList,
    handleGoToMain,
    handleGoToInterviewResult,
    handleGoToInterviewQuestion,
    handleGoToLifeRecodeWrite,
    handleGoToMemberLogin,
    toggleProfileDropdown,
    toggleMobileMenu,
    toggleDropdown,
    logout,
    isAuthenticated,
    mobileMenuOpen,
    dropdownOpen,
    isDropdownProfileOpen,
    dropdownRef,
    profileDropdownRef,
  };
};

export default useHeader;
