import React, { useCallback, useEffect, useState } from 'react';
import DetailItem from './DetailItem'; // Item 컴포넌트 가져오기
import styles from './Detail.module.scss';
import classNames from 'classnames/bind';
import { getCalculateGrade, getUrlPath } from '../../../../utils';
import { Popup } from '../../../../components';
import StarRating from './StarRating';
import { useNavigate } from 'react-router-dom';
import recordQuestionAnalyze from '../../../../api/record-question-analyze';
import {
  GeneratedQuestionsResponse,
  GetGeneratedQuestionsResponse,
  RecordQuestionsGeneratedRequest,
} from '../../../../types/record-question-analyze/api';

const cn = classNames.bind(styles);

export interface DetailListProps {
  setResult: React.Dispatch<React.SetStateAction<boolean>>;
  generatedParam: RecordQuestionsGeneratedRequest;
}

const DetailList = ({ setResult, generatedParam }: DetailListProps) => {
  const itemsPerPage = 10;

  // items를 상태로 설정
  const [items, setItems] = useState<GetGeneratedQuestionsResponse[]>([]);

  const [
    appliedRecordCollegeAdmissionAnalyze,
    setAppliedRecordCollegeAdmissionAnalyze,
  ] = useState<
    GeneratedQuestionsResponse['appliedRecordCollegeAdmissionAnalyze'] | null
  >(null);

  const [currentPage, setCurrentPage] = useState(1);

  // 페이지 데이터 반환 함수
  const getPaginatedItems = useCallback(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  }, [currentPage, itemsPerPage, items]);

  // 전체 페이지 수 계산
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const navigate = useNavigate();

  // 페이지네이션 배열 생성
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1,
  );

  const handlePageChange = (page: number) => {
    window.scrollTo({ top: 0, left: 0 });
    setCurrentPage(page);
  };

  const [noReview, setNoReview] = useState(false);
  const [review, setReview] = useState(false);
  const [starRating1, setStarRating1] = useState(0); // 첫번째 별점
  const [starRating2, setStarRating2] = useState(0); // 두번째 별점
  // textarea의 상태를 관리하는 state
  const [evaluationComment, setEvaluationComment] = useState('');

  const handleRating1 = (rating: number) => {
    setStarRating1(rating);
  };

  const handleRating2 = (rating: number) => {
    setStarRating2(rating);
  };

  // textarea 변경 시 호출되는 함수
  const handleEvaluationCommentChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setEvaluationComment(e.target.value);
  };

  const handleGoToInterviewPreview = useCallback(async () => {
    const response = await recordQuestionAnalyze.createRecodeQuestionIsEvaluate(
      {
        evaluationScore: starRating1,
        evaluationScoreFast: starRating2,
        evaluationComment,
        studentRecordAnalyzeIdx: generatedParam.studentRecordAnalyzeIdx,
      },
    );

    if (response.code === '2000') {
      navigate(
        `/interview/preview?id=${generatedParam.studentRecordIdx}&idx=${generatedParam.studentRecordAnalyzeIdx}`,
      );
    }
  }, [navigate, starRating1, starRating2, evaluationComment, generatedParam]);

  const handleClickDownload = useCallback(async () => {
    const response = await recordQuestionAnalyze.getRecodeQuestionIsEvaluate();

    if (response.code === '2000') {
      if (!response.data) {
        setReview(!response.data);
      } else {
        navigate(
          `/interview/preview?id=${generatedParam.studentRecordIdx}&idx=${generatedParam.studentRecordAnalyzeIdx}`,
        );
      }
    }
  }, [generatedParam]);

  const getRecodeQuestionAnalyze = useCallback(async () => {
    const response =
      await recordQuestionAnalyze.getRecodeQuestionAnalyze(generatedParam);

    if (response.code === '2000' && response.data) {
      if (
        !response.data.appliedRecordCollegeAdmissionAnalyze
          .studentRecordAnalyzeStatus.isDecline
      ) {
        const filter = response.data.generatedQuestions.filter(
          item => item.questionContents,
        );

        setItems(filter);
      }
      setAppliedRecordCollegeAdmissionAnalyze(
        response.data.appliedRecordCollegeAdmissionAnalyze,
      );
    }
  }, [generatedParam]);

  // 질문 내용 변경 핸들러
  const handleAnswerChange = (index: number, value: string) => {
    setItems(prevQuestions =>
      prevQuestions.map((question, i) =>
        i === index ? { ...question, answerContents: value } : question,
      ),
    );
  };

  useEffect(() => {
    getRecodeQuestionAnalyze().then();
  }, []);

  return (
    <div className={cn('item-list-container')}>
      {!!appliedRecordCollegeAdmissionAnalyze && (
        <div className={cn('school-info-container')}>
          <div className={cn('school-info')}>
            {
              appliedRecordCollegeAdmissionAnalyze.studentRecordAnalyzeStatus
                .eduSchoolNmKr
            }{' '}
            |{' '}
            {
              appliedRecordCollegeAdmissionAnalyze.studentRecordAnalyzeStatus
                .studentName
            }{' '}
            |{' '}
            {getCalculateGrade(
              appliedRecordCollegeAdmissionAnalyze.studentRecordAnalyzeStatus
                .studentEntryYear,
            )}{' '}
          </div>
          <div className={cn('school-details')}>
            {
              appliedRecordCollegeAdmissionAnalyze.appliedCollegeAdmission
                .universityName
            }{' '}
            |{' '}
            {
              appliedRecordCollegeAdmissionAnalyze.appliedCollegeAdmission
                .collegeDepartmentName
            }{' '}
            |{' '}
            {
              appliedRecordCollegeAdmissionAnalyze.appliedCollegeAdmission
                .collegeAdmissionName
            }{' '}
          </div>
        </div>
      )}
      {appliedRecordCollegeAdmissionAnalyze?.studentRecordAnalyzeStatus
        .analyzeFeedback && (
        <div className={cn('admin-message-container')}>
          {/* 아이콘 영역 */}
          <div className={cn('icon')}>
            <img
              src={getUrlPath('admin_notice_icon.png')}
              alt="admin_notice_icon"
            />
          </div>
          {/* 메시지 영역 */}

          <div className={cn('message')}>
            <div className={cn('admin-title')}>수시탐탐 관리자</div>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  appliedRecordCollegeAdmissionAnalyze?.studentRecordAnalyzeStatus.analyzeFeedback.replace(
                    /(?:\r\n|\r|\n)/g,
                    '<br />',
                  ),
              }}
            />
          </div>
        </div>
      )}

      {!!items.length && (
        <div className={cn('questions-container')}>
          <p className={cn('question-count')}>
            <span>{items.length}</span>개의 질문이 준비되었습니다.
          </p>
          <div className={cn('pagination')}>
            {pageNumbers.map(number => (
              <button
                key={number}
                className={cn('page-btn', {
                  active: currentPage === number,
                })}
                onClick={() => handlePageChange(number)}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      )}

      {getPaginatedItems().map((item, index) => (
        <DetailItem
          key={item.idx}
          id={`${(currentPage - 1) * itemsPerPage + index + 1 + 1 < 10 ? '0' + (index + 1) : (currentPage - 1) * itemsPerPage + index + 1}`}
          idx={item.idx}
          question={item.questionContents}
          answer={item.answerContents || ''}
          index={index}
          handleAnswerChange={handleAnswerChange}
        />
      ))}

      <div
        className={cn('pagination-container', {
          'only-next': currentPage === 1,
          'only-prev': currentPage === totalPages,
        })}
      >
        {/* 이전 질문 보기 버튼 */}
        {currentPage > 1 && (
          <button
            className={cn('next-button')}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <img
              src={getUrlPath(`left_small.png`)}
              alt="left_small"
              className={cn('arrow-icon')}
            />
            이전 질문 보기
          </button>
        )}

        {/* 다음 질문 보기 버튼 */}
        {currentPage < totalPages && (
          <button
            className={cn('next-button')}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            다음 질문 보기
            <img
              src={getUrlPath(`right_small_gray.png`)}
              alt="right_small_gray"
              className={cn('arrow-icon')}
            />
          </button>
        )}
      </div>

      {/* 하단에 다운로드 및 목록보기 버튼 */}
      <div className={cn('button-container')}>
        <button
          className={cn('button', { secondary: true })}
          onClick={() => setResult(false)}
        >
          목록보기
        </button>
        {!appliedRecordCollegeAdmissionAnalyze?.studentRecordAnalyzeStatus
          .isDecline && (
          <button
            className={cn('button', { primary: true })}
            onClick={handleClickDownload}
          >
            다운로드
          </button>
        )}
      </div>

      <Popup
        isOpen={noReview}
        onClose={() => setNoReview(false)}
        backgroundClickClose={false}
        width="w-[335px] md:w-[453px] lg:w-[800px]"
      >
        <div className={cn('popup-container')}>
          <div className={cn('popup-title')}>다운로드 불가</div>
          <div className={cn('popup-subtitle')}>
            리뷰를 작성하지 않으면 다운로드가 불가능해요. <br />
            수시탐탐 리뷰를 작성해 주세요.
          </div>
          <button
            className={cn('popup-button')}
            onClick={() => {
              setNoReview(false);
              setReview(true);
            }}
          >
            리뷰 작성하기
          </button>
        </div>
      </Popup>

      <Popup
        isOpen={review}
        onClose={() => {
          setNoReview(true);
          setReview(false);
        }}
        backgroundClickClose={false}
        width="w-[335px] md:w-[453px] lg:w-[800px]"
      >
        <div className={cn('popup-container')}>
          <div className={cn('popup-title')}>
            수시탐탐 서비스에 대해 <br /> 소중한 의견을 들려주세요.
          </div>
          <div className={cn('popup-subtitle')}>
            면접 예상 질문은 어땠나요?
            <span className={cn('required')}>*</span> {/* 별표 추가 */}
          </div>
          <StarRating totalStars={5} onRate={handleRating1} />

          <div className={cn('popup-subtitle')}>
            면접 예상 질문에 대한 답변 속도는 만족스러우셨나요?
            <span className={cn('required')}>*</span> {/* 별표 추가 */}
          </div>
          <StarRating totalStars={5} onRate={handleRating2} />
          <div className={cn('popup-subtitle')}>
            수시탐탐 서비스에 대해 자유롭게 의견을 남겨주세요!
            <span className={cn('required')}>*</span> {/* 별표 추가 */}
          </div>
          <textarea
            className={cn('review-textarea')}
            placeholder="의견을 남겨 주세요."
            value={evaluationComment} // state를 value로 설정
            onChange={handleEvaluationCommentChange} // onChange 이벤트 핸들러 추가
          />
          <button
            className={cn('popup-button')}
            onClick={handleGoToInterviewPreview}
          >
            제출
          </button>
        </div>
      </Popup>
    </div>
  );
};

export default DetailList;
