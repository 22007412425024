import React, { useCallback, useEffect, useState } from 'react';
import styles from './Question.module.scss';
import Init from './Init';
import RequestForm from './RequestForm';
import recodeApi from '../../../api/recode';
import { some } from 'lodash-es';
import { StudentRecord } from '../../../types/recode/api';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const Question = () => {
  const [isLifeRecode, setIsLifeRecode] = useState(false);
  const [isInterviewRequest, setIsInterviewRequest] = useState(false);
  const [isApi, setIsApi] = useState(false);
  const [userData, setUserData] = useState<StudentRecord[]>([]);
  const [step, setStep] = useState(0);

  const getRecodeList = useCallback(async () => {
    const response = await recodeApi.getRecodeList();

    if (response.code === '2000') {
      if (response.data) {
        const hasFileInUse = some(response.data, { isFileUse: true });
        const filterNotYet = response.data.filter(item => {
          return item.analyzeStatusType !== 'NOT_YET';
        });

        setIsInterviewRequest(filterNotYet.length === response.data.length);
        setUserData(response.data);
        setIsLifeRecode(hasFileInUse);
      } else {
        setIsLifeRecode(false);
      }
    }

    setIsApi(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 스크롤을 최상단으로 이동
  }, [step]);

  useEffect(() => {
    getRecodeList().then();
  }, []);

  if (!isApi) {
    return <></>;
  }

  return (
    <div className={cn('container')}>
      {step === 0 && (
        <Init
          isLifeRecode={isLifeRecode}
          setStep={setStep}
          isInterviewRequest={isInterviewRequest}
        />
      )}
      {isLifeRecode && step !== 0 && (
        <RequestForm
          isLifeRecode={isLifeRecode}
          userData={userData}
          setStep={setStep}
          step={step}
        />
      )}
    </div>
  );
};

export default Question;
