import React from 'react';
import classNames from 'classnames/bind';
import styles from './Main.module.scss';
import { getUrlPath } from '../../utils';
import Popup from './popup/Popup';

const cn = classNames.bind(styles);

const Main = () => {
  return (
    <div className={cn('container')}>
      <img
        src={getUrlPath(`main/main.png`)}
        alt="main"
        className={cn('main')}
      />
      <img
        src={getUrlPath(`main/main_mo.png`)}
        alt="main_mo"
        className={cn('main-mo')}
      />

      <Popup type="image" popupType="main_launching_event" />
    </div>
  );
};

export default Main;
