import { findIndex } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const useInstructions = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const query = searchParams.get('type');

  const [type, setType] = useState<'government24' | 'nice'>('government24');
  const [tabs, setTabs] = useState<
    {
      label: string;
      active: boolean;
      type: 'government24' | 'nice';
    }[]
  >([
    { label: '재학생/졸업생(정부24)', active: true, type: 'government24' },
    { label: '재학생(나이스플러스)', active: false, type: 'nice' },
  ]);

  const handleTabClick = useCallback(
    (index: number) => {
      setType(tabs[index].type);

      setTabs(
        tabs.map((tab, i) => ({ ...tab, active: i === index })), // 클릭한 탭만 활성화
      );
    },
    [tabs],
  );

  const handleGoToLifeRecodeWrite = useCallback(() => {
    navigate('/liferecode/write');
  }, [navigate]);

  const handleOpenLink = useCallback(() => {
    window.open(
      type === 'government24'
        ? 'https://www.gov.kr/mw/AA020InfoCappView.do?HighCtgCD=A04001&CappBizCD=13410000019'
        : 'https://www.neisplus.kr/',
      '_blank', // 새 창으로 열기
      'noopener,noreferrer', // 보안 이슈 방지
    );
  }, [type]);

  useEffect(() => {
    if (query) {
      const index = findIndex(tabs, props => props.type === query);

      handleTabClick(index);
    }
  }, []);

  return {
    type,
    handleTabClick,
    tabs,
    handleGoToLifeRecodeWrite,
    handleOpenLink,
  };
};

export default useInstructions;
