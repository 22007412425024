import { get } from '../../utils';
import { Response } from '../../types';
import {
  SchoolSearchRequest,
  SchoolSearchResponse,
  UniversityDepartmentsRequest,
  UniversityDepartmentsResponse,
  UniversityResponse,
} from '../../types/common/api';

export class CommonApi {
  getHighSchoolList = async (data: SchoolSearchRequest) => {
    const response: Response<SchoolSearchResponse[]> = await get(
      '/api/common/search-school-high',
      data,
    );

    return response as Response<SchoolSearchResponse[]>;
  };

  // 대학교 리스트
  getUniversityList = async (data: SchoolSearchRequest) => {
    const response: Response<UniversityResponse[]> = await get(
      '/api/common/search-university',
      data,
    );

    return response as Response<UniversityResponse[]>;
  };

  // 대학교 학과 + 전형 리스트
  getUniversityDepartmentsList = async (data: UniversityDepartmentsRequest) => {
    const response: Response<UniversityDepartmentsResponse> = await get(
      '/api/common/search-college-departments-admissions',
      data,
    );

    return response as Response<UniversityDepartmentsResponse>;
  };
}

const commonApi = new CommonApi();
export default commonApi;
