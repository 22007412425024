import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from '../../../../../contexts';
import { StudentRecord } from '../../../../../types/recode/api';
import { getCalculateGrade } from '../../../../../utils';
import recodeApi from '../../../../../api/recode';

export interface UseFileUploadCard {
  setDetailId: React.Dispatch<React.SetStateAction<number>>;
  setInit: React.Dispatch<React.SetStateAction<boolean>>;
  list: StudentRecord[];
  getRecodeList: () => void;
}

const useFileUploadCard = ({
  setDetailId,
  setInit,
  list,
  getRecodeList,
}: UseFileUploadCard) => {
  const navigate = useNavigate();
  const { confirm } = useConfirm();

  const handleGoToLifeRecodeUpload = useCallback(
    (isLifeRecode: boolean, idx: number) => {
      const url = `/liferecode/upload?detail=${idx}`;

      if (isLifeRecode) {
        confirm({
          title: '생기부 다시 업로드 하기',
          subtitle:
            '생기부를 다시 업로드하면<br />기존 등록되어 있던 생기부는 삭제돼요.<br />생기부를 다시 등록 하시겠어요?',
          confirmButtonName: '확인',
          onConfirm: () => navigate(url),
        });

        return;
      }

      navigate(url);
    },
    [navigate],
  );

  const handleGoToInterviewQuestion = useCallback(() => {
    confirm({
      title: '면접 예상 질문 접수',
      subtitle: `${list[0].studentName}, ${list[0].eduSchoolNmKr}, ${getCalculateGrade(list[0].studentEntryYear)}<br />면접 예상 질문을 접수 하시겠어요?<br /> 1계정당, 1회의 면접질문만 접수가 가능해요.`,
      confirmButtonName: '확인',
      onConfirm: () => navigate('/interview/question'),
    });
  }, [navigate]);

  const deleteRecodeApi = useCallback(async (detailNo: number) => {
    const response = await recodeApi.deleteRecode(detailNo);

    if (response.code === '2000') {
      getRecodeList();
    }
  }, []);

  const handleDeleteConfirm = useCallback(
    (detailNo: number) => {
      confirm({
        title: '정보를 삭제하시겠어요?',
        onConfirm: () => deleteRecodeApi(detailNo),
        confirmButtonName: '삭제',
        closeButtonName: '취소',
      });
    },
    [confirm, deleteRecodeApi],
  );

  const handleGoToRecodeForm = useCallback((idx: number) => {
    setDetailId(idx);
    setInit(false);
  }, []);

  return {
    handleGoToLifeRecodeUpload,
    handleGoToInterviewQuestion,
    handleDeleteConfirm,
    handleGoToRecodeForm,
  };
};

export default useFileUploadCard;
