import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import boardApi from '../../../../api/board';
import { toNumber } from 'lodash-es';
import { BoardResponse } from '../../../../types/board/api';

const useDetail = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const id = parseInt(queryParams.get('idx') || '', 10);

  const [item, setItem] = useState<BoardResponse>();

  const handleGoToBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const getBoardDetail = useCallback(async () => {
    const response = await boardApi.getBoardDetail({
      boardPostIdx: toNumber(id),
    });

    if (response.code === '2000' && response.data) {
      setItem(response.data);
    }
  }, [id]);

  useEffect(() => {
    getBoardDetail().then();
  }, [id]);
  return { item, handleGoToBack };
};

export default useDetail;
