import React from 'react';
import { getUrlPath } from '../../utils';
import styles from './Header.module.scss';
import classNames from 'classnames/bind';
import Stack from '../Stack';
import useHeader from './hooks/useHeader';

const cn = classNames.bind(styles);

const Header = () => {
  const {
    dropdownOpen,
    handleGoToInstructions,
    handleGoToMemberInfo,
    handleGoToNoticeList,
    handleGoToMain,
    handleGoToInterviewResult,
    handleGoToInterviewQuestion,
    handleGoToLifeRecodeWrite,
    handleGoToMemberLogin,
    isDropdownProfileOpen,
    mobileMenuOpen,
    toggleDropdown,
    toggleMobileMenu,
    toggleProfileDropdown,
    dropdownRef,
    profileDropdownRef,
    logout,
    isAuthenticated,
  } = useHeader();

  return (
    <div className={cn(`header`)}>
      <div
        className={cn(`overlay`, {
          active: mobileMenuOpen,
        })}
      />

      <header className={cn('header-container')}>
        {/* 모바일 전용 햄버거 메뉴 아이콘 */}
        <div className={cn('menu-icon')} onClick={toggleMobileMenu}>
          <img src={getUrlPath(`menu_icon.png`)} alt="menu_icon" />
        </div>

        <div className={cn('logo')} onClick={handleGoToMain}>
          <img src={getUrlPath(`logo.png`)} alt="Logo" />
        </div>

        <nav
          className={cn(`gnb-menu`, {
            active: mobileMenuOpen,
          })}
          ref={dropdownRef}
        >
          <div className={cn('menu-item')} onClick={handleGoToInstructions}>
            이용가이드
          </div>
          <div className={cn('menu-item')} onClick={handleGoToLifeRecodeWrite}>
            생기부 등록
          </div>
          <div
            className={cn('menu-item')}
            onMouseEnter={() => toggleDropdown()}
            onMouseLeave={() => toggleDropdown('leave')}
          >
            <Stack direction="horizontal" className={cn('space-between')}>
              면접 예상 질문
              <div className={cn('flag-icon')}>
                <img
                  src={getUrlPath(
                    !dropdownOpen ? 'down_icon.png' : 'up_icon.png',
                  )}
                  alt={!dropdownOpen ? 'down_icon.png' : 'up_icon.png'}
                />
              </div>
            </Stack>
            <div
              className={cn(`dropdown-menu`, {
                active: dropdownOpen,
              })}
            >
              <div
                className={cn('dropdown-item')}
                onClick={handleGoToInterviewQuestion}
              >
                면접 예상 질문
              </div>
              <div
                className={cn('dropdown-item')}
                onClick={handleGoToInterviewResult}
              >
                접수 결과 확인
              </div>
            </div>
          </div>
        </nav>
        {!isAuthenticated ? (
          <div className={cn('login')} onClick={handleGoToMemberLogin}>
            로그인
          </div>
        ) : (
          <div
            className={cn('profile-container')}
            onClick={toggleProfileDropdown}
            ref={profileDropdownRef}
          >
            <img
              className={cn('profile-icon')}
              src={getUrlPath(`member_icon.png`)}
              alt="Logo"
            />
            <div
              className={cn('dropdown', {
                active: isDropdownProfileOpen,
              })}
            >
              <div
                className={cn('dropdown-item')}
                onClick={handleGoToMemberInfo}
              >
                회원정보
              </div>
              <div
                className={cn('dropdown-item')}
                onClick={handleGoToNoticeList}
              >
                알림사항
              </div>
              <div
                className={cn('dropdown-item')}
                onClick={() => logout(false)}
              >
                로그아웃
              </div>
            </div>
          </div>
        )}

        {/* 모바일 메뉴 */}
        <div
          className={cn(`mobile-menu`, {
            active: mobileMenuOpen,
          })}
        >
          {/* 모바일 전용 햄버거 메뉴 아이콘 */}
          <div className={cn('menu-icon')} onClick={toggleMobileMenu}>
            <img src={getUrlPath(`close_icon.png`)} alt="close_icon" />
          </div>

          <div className={cn('menu-item')} onClick={handleGoToInstructions}>
            이용가이드
          </div>
          <div className={cn('menu-item')} onClick={handleGoToLifeRecodeWrite}>
            생기부 등록
          </div>
          <div
            className={cn('menu-item', 'dropdown', {
              'toggle-dropdown': dropdownOpen,
            })}
            ref={dropdownRef}
            onClick={() => toggleDropdown()}
          >
            <Stack direction="horizontal" className={cn('space-between')}>
              면접 예상 질문
              {/* 모바일 전용 햄버거 메뉴 아이콘 */}
              <div className={cn('flag-icon')} onClick={toggleMobileMenu}>
                <img
                  src={getUrlPath(
                    !dropdownOpen ? 'down_icon.png' : 'up_icon.png',
                  )}
                  alt={!dropdownOpen ? 'down_icon.png' : 'up_icon.png'}
                />
              </div>
            </Stack>
            <div
              className={cn(`dropdown-menu`, {
                active: dropdownOpen,
              })}
            >
              <div
                className={cn('dropdown-item')}
                onClick={handleGoToInterviewQuestion}
              >
                면접 예상 질문
              </div>
              <div
                className={cn('dropdown-item')}
                onClick={handleGoToInterviewResult}
              >
                접수 결과 확인
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
