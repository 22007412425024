import { useCallback } from 'react';

const useFooter = () => {
  const handleGoToTerms = useCallback(() => {
    window.open(
      'https://funblehr.notion.site/12745ad1ceba80fd8353f5290c27f728',
      '_blank', // 새 창으로 열기
      'noopener,noreferrer', // 보안 이슈 방지
    );
  }, []);

  const handleGoToPrivacy = useCallback(() => {
    window.open(
      'https://funblehr.notion.site/12745ad1ceba80ce9acedba230943780',
      '_blank', // 새 창으로 열기
      'noopener,noreferrer', // 보안 이슈 방지
    );
  }, []);

  const handleGoToInstagram = useCallback(() => {
    window.open(
      '  https://www.instagram.com/soosi_official/\n',
      '_blank', // 새 창으로 열기
      'noopener,noreferrer', // 보안 이슈 방지
    );
  }, []);

  return {
    handleGoToInstagram,
    handleGoToPrivacy,
    handleGoToTerms,
  };
};

export default useFooter;
