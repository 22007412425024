import React from 'react';
import classNames from 'classnames/bind';
import { Popup } from '../../../components';
import styles from './Popup.module.scss';
import { getUrlPath } from '../../../utils';
import usePopup from './hooks/usePopup';

const cn = classNames.bind(styles);

export interface MainPopupProps {
  type: 'image' | 'text';
  popupType: string;
}

const MainPopup = ({ type, popupType }: MainPopupProps) => {
  const {
    handleImageLoad,
    handleCloseClick,
    isImageLoaded,
    popupOpen,
    setPopupOpen,
  } = usePopup({ popupType });

  return (
    <>
      <Popup
        isOpen={popupOpen && isImageLoaded}
        onClose={() => setPopupOpen(false)}
        backgroundClickClose={false}
        isCloseButton={false}
        width="w-[375px] lg:w-[450px]"
        customMargin=""
      >
        <div className={cn('popup-container')}>
          {type === 'image' && (
            <img src={getUrlPath(`popup/${popupType}.png`)} alt={popupType} />
          )}
          <div className={cn('buttonRow')}>
            <button
              className={cn('popup-button-one-day', { button: true })}
              onClick={() => handleCloseClick(true)}
            >
              오늘 그만보기
            </button>
            <button
              className={cn('popup-button-close', { button: true })}
              onClick={() => handleCloseClick(false)}
            >
              닫기
            </button>
          </div>
        </div>
      </Popup>
      {/* 이미지 미리 로드 */}
      <img
        src={getUrlPath(`popup/${popupType}.png`)}
        alt={popupType}
        style={{ display: 'none' }}
        onLoad={handleImageLoad}
      />
    </>
  );
};

export default MainPopup;
