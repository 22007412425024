import React, { useState } from 'react';
import styles from './Detail.module.scss'; // 별점 관련 SCSS 파일
import { getUrlPath } from '../../../../utils';
import classNames from 'classnames/bind';

export interface StarRatingProps {
  totalStars: number;
  onRate: (rating: number) => void;
}
const cn = classNames.bind(styles);

const StarRating = ({ totalStars, onRate }: StarRatingProps) => {
  const [rating, setRating] = useState(0);

  const handleClick = (index: number) => {
    setRating(index + 1);
    onRate(index + 1); // 선택된 별점 전달
  };

  return (
    <div className={cn('star-rating')}>
      {[...Array(totalStars)].map((_, index) => (
        <img
          key={index}
          src={
            index < rating
              ? getUrlPath('interview/fill_star.png')
              : getUrlPath('interview/empty_star.png')
          }
          alt={`Star ${index + 1}`}
          className={cn('star')}
          onClick={() => handleClick(index)}
        />
      ))}
    </div>
  );
};

export default StarRating;
