import Cookies from 'js-cookie';

/**
 * Set a cookie
 * @param name - Name of the cookie
 * @param value - Value to store
 * @param options - Additional options like expiration, path, etc.
 */
export const setCookie = (
  name: string,
  value: string,
  options?: Cookies.CookieAttributes,
): void => {
  Cookies.set(name, value, options);
};

/**
 * Get a cookie
 * @param name - Name of the cookie to retrieve
 * @returns The cookie value or undefined if not found
 */
export const getCookie = (name: string): string | undefined => {
  return Cookies.get(name);
};

/**
 * Remove a cookie
 * @param name - Name of the cookie to remove
 * @param options - Options like path, domain (if set during creation)
 */
export const removeCookie = (
  name: string,
  options?: Cookies.CookieAttributes,
): void => {
  Cookies.remove(name, options);
};
