import React from "react";
import styles from "./TabMenu.module.scss";
import classNames from "classnames/bind";

const cn = classNames.bind(styles);
export interface TabMenuProps {
  tabs: { label: string; active: boolean }[]; // 각 탭의 이름과 활성화 여부
  handleTabClick: (index: number) => void; // 탭을 클릭했을 때 실행될 함수
}

const TabMenu = ({ tabs, handleTabClick }: TabMenuProps) => {
  return (
    <div className={cn("tab-container")}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={cn("tab-button", {
            active: tab.active,
          })}
          onClick={() => handleTabClick(index)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default TabMenu;
