import { get } from '../../utils';
import { Response } from '../../types';
import {
  BoardDetailRequest,
  BoardListRequest,
  BoardResponse,
} from '../../types/board/api';

export class BoardApi {
  getBoardList = (data: BoardListRequest) => {
    return get<Response<BoardResponse[]>>('/api/board', data);
  };

  // 대학교 리스트
  getBoardDetail = ({ boardPostIdx }: BoardDetailRequest) => {
    return get<Response<BoardResponse>>(`/api//board/${boardPostIdx}`);
  };
}

const boardApi = new BoardApi();
export default boardApi;
