import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAlert } from '../../../../contexts';
import { ALLOWED_FILE_TYPES, MAX_FILE_SIZE } from '../../../../constants';
import recodeApi from '../../../../api/recode';
import { toNumber } from 'lodash-es';
import { getCalculateGrade } from '../../../../utils';

const useUpload = () => {
  const navigate = useNavigate();
  const { alert } = useAlert();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('detail');

  const [information, setInformation] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const [isPrivacyConsentOpen, setPrivacyConsentOpen] =
    useState<boolean>(false);
  const [isSensitiveInfoConsentOpen, setSensitiveInfoConsentOpen] =
    useState<boolean>(false);
  // 체크박스 상태 관리
  const [isPrivacyConsentChecked, setPrivacyConsentChecked] =
    useState<boolean>(false);
  const [isSensitiveInfoConsentChecked, setSensitiveInfoConsentChecked] =
    useState<boolean>(false);
  const [isRecordConsentChecked, setRecordConsentChecked] =
    useState<boolean>(false);

  // 체크박스 변경 핸들러
  const handlePrivacyConsentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPrivacyConsentChecked(event.target.checked);
  };

  const handleSensitiveInfoConsentChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setSensitiveInfoConsentChecked(event.target.checked);
  };

  const handleRecordConsentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRecordConsentChecked(event.target.checked);
  };

  const handlePrivacyConsentClose = () =>
    setPrivacyConsentOpen(!isPrivacyConsentOpen);

  const handleSensitiveInfoConsentClose = () =>
    setSensitiveInfoConsentOpen(!isSensitiveInfoConsentOpen);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      // 파일 형식 확인
      if (!ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
        alert({
          title: '파일을 업로드해 주세요.',
          subtitle: 'PDF 또는 HTML 파일을 업로드해 주세요.',
        });

        return;
      }

      if (selectedFile.size > MAX_FILE_SIZE) {
        alert({
          title: '첨부 실패했어요.',
          subtitle:
            '파일 첨부 용량이 초과되었어요. <br/> 최대 50MB 이하로 등록 가능해요.',
        });
        return;
      }

      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const getRecodeDetailApi = useCallback(async () => {
    const response = await recodeApi.getRecode(toNumber(query));

    if (response.code === '2000' && response.data) {
      const {
        studentName,
        studentEntryYear,
        eduSchoolNmKr,
        isFileUse,
        fileOriginName,
      } = response.data;

      setInformation(
        `${studentName} | ${eduSchoolNmKr} | ${getCalculateGrade(studentEntryYear)}`,
      );

      if (isFileUse) {
        setFileName(fileOriginName);
      }
    }
  }, [query]);

  // 파일 업로드 함수
  const handleFileUpload = async () => {
    // 필수 값이 없는 경우 alert 띄우기
    if (!file) {
      alert({ title: '파일을 선택해 주세요.' });
      return;
    }
    if (!isPrivacyConsentChecked) {
      alert({ title: '개인정보 수집 및 이용 동의를 체크해 주세요.' });
      return;
    }
    if (!isSensitiveInfoConsentChecked) {
      alert({ title: '민감정보 수집 및 이용 동의를 체크해 주세요.' });
      return;
    }
    if (!isRecordConsentChecked) {
      alert({ title: '본인의 생활기록부 내용임을 확인해 주세요.' });
      return;
    }

    if (!toNumber(query)) {
      alert({ title: '이상한 데이터가 넘어왔습니다.' });
      return;
    }

    try {
      const recordData = {
        studentRecordIdx: toNumber(query),
        file: file,
      };

      const response = await recodeApi.createRecodeFile(recordData);

      if (response.code === '2000') {
        navigate(-1);
      }
    } catch (error) {
      console.error('파일 업로드 실패:', error);
    }
  };

  const handleGoToBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    getRecodeDetailApi().then();
  }, [query]);

  return {
    file,
    fileName,
    handleFileChange,
    handleFileUpload,
    handleGoToBack,
    handlePrivacyConsentChange,
    handlePrivacyConsentClose,
    handleRecordConsentChange,
    handleSensitiveInfoConsentChange,
    handleSensitiveInfoConsentClose,
    isPrivacyConsentChecked,
    isPrivacyConsentOpen,
    isRecordConsentChecked,
    isSensitiveInfoConsentChecked,
    isSensitiveInfoConsentOpen,
    information,
  };
};

export default useUpload;
