import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { AuthContextType } from '../types';
import { getItem, removeItem } from '../utils/sessionStorage';
import { SESSION_STORAGE } from '../constants';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!getItem(`${SESSION_STORAGE.init}${SESSION_STORAGE.loginToken}`),
  );
  const [token, setToken] = useState<string | null>(null);

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = (isSecession?: boolean) => {
    setIsAuthenticated(false);
    removeItem(`${SESSION_STORAGE.init}${SESSION_STORAGE.loginToken}`);
    removeItem(`${SESSION_STORAGE.init}${SESSION_STORAGE.refreshToken}`);

    if (!isSecession) {
      navigate('/');
    }
  };

  useEffect(() => {
    const isLogin = !!getItem(
      `${SESSION_STORAGE.init}${SESSION_STORAGE.loginToken}`,
    );

    setIsAuthenticated(isLogin);
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, setToken, token }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
