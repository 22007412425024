import { del, get, post, put } from '../../utils';
import { Response } from '../../types';
import {
  CreateFileRecodeRequest,
  CreateStudentRecordRequest,
  StudentRecord,
} from '../../types/recode/api';

export class RecodeApi {
  getRecodeList = async () => {
    const response: Response<StudentRecord[]> = await get(
      '/api/student-record/list',
    );

    return response as Response<StudentRecord[]>;
  };

  getRecode = async (studentRecordIdx: number) => {
    const response: Response<StudentRecord> = await get(
      `/api/student-record/${studentRecordIdx}`,
    );

    return response as Response<StudentRecord>;
  };

  createRecode = async (data: CreateStudentRecordRequest) => {
    const response: Response<{}> = await post('/api/student-record', data);

    return response as Response<{}>;
  };

  updateRecode = async (
    data: CreateStudentRecordRequest,
    studentRecordIdx: number,
  ) => {
    const req = {
      ...data,
      studentRecordIdx,
    };

    const response: Response<{}> = await put(`/api/student-record`, req);

    return response as Response<{}>;
  };

  deleteRecode = async (studentRecordIdx: number) => {
    const response: Response<{}> = await del(
      `/api/student-record/${studentRecordIdx}`,
    );

    return response as Response<{}>;
  };

  createRecodeFile = async (data: CreateFileRecodeRequest) => {
    const formData = new FormData();
    formData.append('file', data.file);

    const response: Response<{}> = await post(
      `/api/student-record/${data.studentRecordIdx}/file`,
      formData,
      'multipart/form-data',
    );

    return response as Response<{}>;
  };
}

const recodeApi = new RecodeApi();
export default recodeApi;
