import React from 'react';

export interface ModalProps {
  title: string;
  subtitle?: string;
  zIndex?: number;
  confirmButtonName?: string;
  closeButtonName?: string;
  onClose?: () => void;
  onConfirm?: () => void;
}

const Modal = ({
  title,
  subtitle,
  zIndex = 99,
  confirmButtonName,
  closeButtonName,
  onClose,
  onConfirm,
}: ModalProps) => {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 "
      style={{ zIndex }} // zIndex 적용
    >
      {/* 모달 크기를 화면에 맞게 조정 */}
      <div className="bg-white p-6 rounded-lg shadow-lg w-full text-left  max-w-[335px] sm:max-w-[453px]">
        {/* 모달 제목 */}
        <h2
          className="text-[20px] sm:text-[24px] font-bold"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {/* 모달 서브타이틀 */}
        {subtitle && (
          <p
            className="text-gray-600 mt-2 text-[16px]"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}

        {/* 모달 버튼 영역 */}
        <div className="mt-6 flex justify-center space-x-4 width100">
          {onClose && (
            <button
              className={`${
                onConfirm ? 'w-1/2' : 'w-full'
              } bg-gray-300 text-black px-4 py-2 rounded-md`}
              onClick={onClose}
            >
              {closeButtonName}
            </button>
          )}
          {onConfirm && (
            <button
              className={`${
                onClose ? 'w-1/2' : 'w-full'
              } bg-primary-300 text-white px-4 py-2 rounded-md`}
              onClick={onConfirm}
            >
              {confirmButtonName}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
