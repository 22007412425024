import React from 'react';
import styles from './RecodeForm.module.scss';
import { getUrlPath } from '../../../../utils';
import classNames from 'classnames/bind';
import useRecodeRecode from './hooks/useRecodeRecode';

export interface RecodeFormProps {
  detailNo: number;
  setInit: React.Dispatch<React.SetStateAction<boolean>>;
  getRecodeList: () => void;
}
const cn = classNames.bind(styles);

const RecodeForm = ({ detailNo, setInit, getRecodeList }: RecodeFormProps) => {
  const {
    name,
    setName,
    handleYearSearch,
    yearOptions,
    handleSchoolSearch,
    handleSchoolChange,
    search,
    handleGradeChange,
    isSchoolSearch,
    grades,
    setSearchIdx,
    handleSubmit,
    schoolType,
    setSchoolType,
    setIsSchoolSearch,
    result,
    selectedYear,
    setSelectedYear,
    yearDropdownRef,
    schoolDropdownRef,
    setYearOption,
    selectedGrade,
  } = useRecodeRecode({ detailNo, setInit, getRecodeList });

  return (
    <>
      <div className={cn('form-container')}>
        <div className={cn('form-group')}>
          <label className={cn('label')}>
            이름
            <span className={cn('required')}>*</span> {/* 별표 추가 */}
          </label>
          <input
            type="text"
            className={cn('input')}
            placeholder="홍길동"
            value={name}
            onChange={e => setName(e.target.value)} // 이름 입력 핸들러
          />
        </div>
        {/* 현재 학년 */}
        <div className={cn('form-group')}>
          <label className={cn('label')}>
            현재학년 <span className={cn('required')}>*</span>
            {/* 별표 추가 */}
          </label>
          <div className={cn('radio-group')}>
            {grades.map(grade => (
              <label key={grade.value}>
                <input
                  type="radio"
                  name="grade"
                  value={grade.value}
                  onChange={handleGradeChange}
                  checked={selectedGrade === grade.value} // 현재 선택된 값과 비교
                />
                {grade.label}
              </label>
            ))}
          </div>
        </div>
        {/* 입학년도 드롭다운 */}
        <div className={cn('form-group')} ref={yearDropdownRef}>
          <label className={cn('label')}>
            입학년도 <span className={cn('required')}>*</span>
            {/* 별표 추가 */}
          </label>
          <div className={cn('dropdown-wrapper')}>
            <div className={cn('input-wrapper')} onClick={handleYearSearch}>
              <input
                type="text"
                className={cn('input-year')}
                placeholder="입학년도를 선택해 주세요."
                value={selectedYear}
                onChange={e => setSelectedYear(e.target.value)}
                readOnly
              />
              <img
                className={`${cn('down-icon')}`}
                src={getUrlPath(`down_icon.png`)}
                alt="down_icon"
              />
            </div>
            {yearOptions && yearOptions.length > 0 && (
              <ul className={cn('dropdown-menu')}>
                {yearOptions.map((year, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setSelectedYear(year);
                      setYearOption([]);
                    }}
                  >
                    {year}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* 고등학교 명 검색 및 자동완성 드롭다운 */}
        <div
          className={cn('form-group', {
            'high-scroll-search': true,
          })}
          ref={schoolDropdownRef}
        >
          <label className={cn('label')}>
            고등학교 명 <span className={cn('required')}>*</span>
            {/* 별표 추가 */}
          </label>
          <div className={cn('dropdown-wrapper')}>
            <input
              type="text"
              className={cn('input')}
              placeholder="고등학교 이름을 2글자 이상 입력해 주세요."
              value={search}
              onChange={e => {
                handleSchoolChange(e.target.value);
              }} // 검색어 입력 시 검색 실행
            />
            <button
              className={cn('search-button')}
              onClick={handleSchoolSearch}
            >
              검색
            </button>

            {isSchoolSearch &&
              (result.length > 0 ? (
                <ul
                  className={cn('dropdown-menu', {
                    'high-scroll-search': true,
                  })}
                >
                  {result.map((school, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        if (school) {
                          setSchoolType(school.schoolSeparation);
                          handleSchoolChange(school.schoolName); // 학교를 선택하면 검색어 업데이트
                          setSearchIdx(school.idx);
                          setIsSchoolSearch(false);
                        }
                      }}
                    >
                      <div className={cn('school-info')}>
                        <div className={cn('school-type')}>
                          {school.schoolName} ({school.schoolSeparation})
                        </div>
                        <div className={cn('school-address')}>
                          {school.schoolAddress}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className={cn('dropdown-menu')}>
                  <div className={cn('no-results')}>
                    <img src={getUrlPath('empty_box.png')} alt="No Results" />
                    <span>검색 결과가 없어요.</span>
                  </div>
                </ul>
              ))}
          </div>
        </div>

        <div className={cn('form-group')}>
          <label className={cn('label')}>학교유형</label>
          <input
            type="text"
            className={cn('input')}
            placeholder="고등학교 유형을 입력해 주세요."
            value={schoolType}
            disabled
          />
        </div>

        <div
          className={cn('button-group', {
            alone: !detailNo,
          })}
        >
          {!!detailNo && (
            <button
              className={cn('cancel-button')}
              onClick={() => {
                setInit(true);
              }}
            >
              취소
            </button>
          )}
          <button className={cn('submit-button')} onClick={handleSubmit}>
            등록
          </button>
        </div>
      </div>
    </>
  );
};

export default RecodeForm;
