import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  KeyboardEvent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { getItem, removeItem, setItem } from '../../../../utils/sessionStorage';
import { SESSION_STORAGE } from '../../../../constants';
import { toNumber } from 'lodash-es';
import boardApi from '../../../../api/board';
import { BoardResponse } from '../../../../types/board/api';

const useList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>(''); // 검색어 상태
  const [items, setItems] = useState<BoardResponse[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);

  const itemsPerPage = 10; // 한 페이지에 표시할 항목 수

  const handleGoToDetail = useCallback(
    (index: number) => {
      // 페이지 이동할 때, 페이지 번호 기억나게 해줌
      setItem(
        `${SESSION_STORAGE.init}${SESSION_STORAGE.noticePage}`,
        currentPage,
      );

      navigate(`/notice/detail?idx=${index}`);
    },
    [currentPage, navigate],
  );

  const handlePageChange = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      setCurrentPage(prev => prev - 1); // 이전 페이지로 이동
    } else if (direction === 'next') {
      setCurrentPage(prev => prev + 1); // 다음 페이지로 이동
    }
  };

  // 검색어 입력 시 상태 업데이트
  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  // input이 blur 되었을 때 검색 함수 호출
  const handleBlur = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        getBoardList(1).then();
        setCurrentPage(1);
      }
    },
    [searchTerm],
  );

  const getBoardList = useCallback(
    async (page?: number) => {
      const response = await boardApi.getBoardList({
        page: page || currentPage,
        size: itemsPerPage,
        postsTitle: searchTerm || undefined,
      });

      if (response.code === '2000' && response.data) {
        setItems(response.data);
        setTotalPage(response?.pagingInfo?.totalPages || 0);
      }
    },
    [currentPage, searchTerm],
  );

  useEffect(() => {
    if (getItem(`${SESSION_STORAGE.init}${SESSION_STORAGE.noticePage}`)) {
      setCurrentPage(
        toNumber(
          getItem(`${SESSION_STORAGE.init}${SESSION_STORAGE.noticePage}`),
        ),
      );

      removeItem(`${SESSION_STORAGE.init}${SESSION_STORAGE.noticePage}`);
    }
  }, [currentPage]);

  useEffect(() => {
    getBoardList().then();
  }, [currentPage]);

  return {
    handleGoToDetail,
    items,
    handlePageChange,
    handleInputChange,
    handleBlur,
    totalPage,
    currentPage,
    searchTerm,
  };
};

export default useList;

/**
 * 프론트 노출을 허용하지않았는데, 페이지에서는 2페이지로 데이터 내려옴
 */
