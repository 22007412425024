import { get, post } from '../../utils';
import { Response } from '../../types';
import {
  GeneratedQuestionsResponse,
  GetCollegeAdmissionApplicationResponse,
  GetRecordQuestionAnalyzeResponse,
  RecodeQuestionMyAnswerRequest,
  RecordQuestionsEvaluateRequest,
  RecordQuestionsEvaluateResponse,
  RecordQuestionsGeneratedRequest,
} from '../../types/record-question-analyze/api';

export class RecordQuestionAnalyze {
  getRecodeQuestionAnalyzeList() {
    return get<
      Response<
        {
          studentRecordAnalyzeStatus: GetRecordQuestionAnalyzeResponse;
          appliedCollegeAdmission: GetCollegeAdmissionApplicationResponse;
        }[]
      >
    >('/api/record-question-analyze/applied');
  }

  getRecodeQuestionAnalyze(params: RecordQuestionsGeneratedRequest) {
    return get<Response<GeneratedQuestionsResponse>>(
      '/api/record-question-analyze/generated',
      params,
    );
  }

  getRecodeQuestionIsEvaluate() {
    return get<Response<RecordQuestionsEvaluateResponse>>(
      '/api/record-question-analyze/isEvaluate',
    );
  }

  createRecodeQuestionIsEvaluate(body: RecordQuestionsEvaluateRequest) {
    return post<Response<{}>, RecordQuestionsEvaluateRequest>(
      '/api/record-question-analyze/evaluate',
      body,
    );
  }

  createRecodeQuestionMyAnswer(body: RecodeQuestionMyAnswerRequest) {
    return post<Response<{}>, RecodeQuestionMyAnswerRequest>(
      '/api/record-question-analyze/myAnswer',
      body,
    );
  }
}
// QuestionsApi 클래스의 인스턴스를 생성하고 export합니다.
const recordQuestionAnalyze = new RecordQuestionAnalyze();
export default recordQuestionAnalyze;
