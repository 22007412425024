import React from 'react';
import styles from '../Result.module.scss';
import classNames from 'classnames/bind';
import CardItem, { CardItemProps } from './CardItem';
import { RecordQuestionsGeneratedRequest } from '../../../../types/record-question-analyze/api';

const cn = classNames.bind(styles);

export interface CardListProps {
  items: CardItemProps[];
  setResult: React.Dispatch<React.SetStateAction<boolean>>;
  setGeneratedParam: React.Dispatch<
    React.SetStateAction<RecordQuestionsGeneratedRequest>
  >;
}

const CardList = ({ items, setResult, setGeneratedParam }: CardListProps) => {
  return (
    <>
      <div className={cn('card-list')}>
        {items.map((item, index) => (
          <CardItem
            key={index}
            {...item}
            setResult={setResult}
            setGeneratedParam={setGeneratedParam}
          />
        ))}
      </div>
      <div className={cn('admin-message-container')}>
        {/* 메시지 영역 */}
        <div className={cn('message')}>
          <div className={cn('admin-title')}>왜 시간이 걸리나요?</div>
          <p>
            단순한 정보 나열이 아닌 접수된 생기부 분석을 기반으로 맞춤형 질문을
            설계하기 때문이에요.
          </p>
        </div>
      </div>
    </>
  );
};

export default CardList;
