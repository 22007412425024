import axios from 'axios';

// Axios 인스턴스 생성
export const kakaoApi = axios.create({
  baseURL: process.env.REACT_APP_KAKAO_API_URL,
  timeout: 10000,
});

// 현재 토큰 갱신 요청 중인지 추적하는 변수
let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const onTokenRefreshed = (token: string) => {
  refreshSubscribers.map(callback => callback(token));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

export const kakaoRefreshToken = async (): Promise<string | null> => {
  try {
    const response = await kakaoApi.post<{ accessToken: string }>(
      '/auth/refresh-token',
      {
        refreshToken: localStorage.getItem('refreshToken'),
      },
    );
    const newAccessToken = response.data.accessToken;

    // 새 토큰 저장
    localStorage.setItem('accessToken', newAccessToken);
    kakaoApi.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;

    // 구독자들에게 새 토큰을 전달하여 대기 중인 요청을 재시도
    onTokenRefreshed(newAccessToken);

    return newAccessToken;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    return null;
  }
};

// 요청 인터셉터 설정
kakaoApi.interceptors.request.use(
  config => {
    if (config.headers) {
      if (config.headers && config.headers['Content-Type']) {
        // eslint-disable-next-line no-self-assign
        config.headers['Content-Type'] = config.headers['Content-Type'];
      }
    }
    return config;
  },
  error => Promise.reject(error),
);

// 응답 인터셉터 설정
kakaoApi.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, please log in again.');
      // 이미 갱신 요청 중이라면, 대기하고 새 토큰으로 요청 재시도
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newToken = await kakaoRefreshToken();
          isRefreshing = false;

          if (newToken) {
            // 기존 요청의 헤더를 새 토큰으로 업데이트
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            // 요청을 재시도
            return kakaoApi(originalRequest);
          }
        } catch (refreshError) {
          isRefreshing = false;
          return Promise.reject(refreshError);
        }
      }

      // 다른 요청들이 토큰 갱신을 기다리도록 설정
      return new Promise(resolve => {
        addRefreshSubscriber((newToken: string) => {
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          resolve(kakaoApi(originalRequest));
        });
      });
    }
    return Promise.reject(error);
  },
);

// GET 요청
export const kakaoGet = async <T>({
  url,
  params,
  headerTest,
}: {
  url: string;
  params?: any;
  headerTest: { token: string };
}): Promise<T> => {
  const response = await kakaoApi.get<T>(url, {
    params,
    headers: {
      Authorization: `Bearer ${headerTest.token}`, // Authorization 헤더에 토큰 추가
    },
  });
  return response as T;
};

// POST 요청
export const kakaoPost = async <T, U>(
  url: string,
  data: U,
  contentType: string = 'application/json',
): Promise<T> => {
  const response = await kakaoApi.post<T>(url, data, {
    headers: {
      'Content-Type': contentType,
    },
  });
  return response as T;
};

// PUT 요청
export const kakaoPut = async <T, U>(
  url: string,
  data: U,
  contentType: string = 'application/json',
): Promise<T> => {
  const response = await kakaoApi.put<T>(url, data, {
    headers: {
      'Content-Type': contentType,
    },
  });
  return response as T;
};

// DELETE 요청
export const kakaoDel = async <T>(
  url: string,
  contentType: string = 'application/json',
): Promise<T> => {
  const response = await kakaoApi.delete<T>(url, {
    headers: {
      'Content-Type': contentType,
    },
  });
  return response as T;
};

export default kakaoApi;
