import React from 'react';
import styles from './Upload.module.scss';
import { getUrlPath } from '../../../utils';
import classNames from 'classnames/bind';
import { Popup } from '../../../components';
import useUpload from './hooks/useUpload';

const cn = classNames.bind(styles);

const Upload = () => {
  const {
    fileName,
    handleFileChange,
    handleFileUpload,
    handleGoToBack,
    handlePrivacyConsentChange,
    handlePrivacyConsentClose,
    handleRecordConsentChange,
    handleSensitiveInfoConsentChange,
    handleSensitiveInfoConsentClose,
    isPrivacyConsentChecked,
    isPrivacyConsentOpen,
    isRecordConsentChecked,
    isSensitiveInfoConsentChecked,
    isSensitiveInfoConsentOpen,
    information,
  } = useUpload();

  return (
    <div className={cn('container')}>
      <h1 className={cn('title')}>생기부 업로드</h1>
      <div className={cn('admin-message-container')}>
        {/* 아이콘 영역 */}
        <div className={cn('icon')}>
          <img
            src={getUrlPath('information_icon.png')}
            alt="information_icon"
          />
        </div>
        {/* 메시지 영역 */}
        <div className={cn('message')}>
          <p>
            생활기록부 파일 등록 시 정부24에서 다운로드한 PDF 파일,
            나이스플러스에서 다운로드한 PDF 또는 HTML 파일만 분석가능합니다.
          </p>
        </div>
      </div>
      <div className={cn('centered-container')}>
        <div className={cn('content')}>{information}</div>
      </div>

      <div className={cn('uploadSection')}>
        <h2 className={cn('title')}>파일첨부</h2>
        <div className={cn('fileInputContainer')}>
          {/* 파일명 표시 텍스트 필드 */}
          <input
            type="text"
            value={fileName}
            readOnly
            className={cn('fileNameInput')}
            placeholder="PDF 또는 HTML 파일만 업로드가 가능해요."
          />

          {/* 숨겨진 파일 입력 필드 */}
          <input
            type="file"
            id="fileUpload"
            className={cn('hiddenFileInput')}
            accept=".pdf,.html" // 파일 확장자 제한
            onChange={handleFileChange}
          />

          {/* 업로드 버튼 */}
          <label htmlFor="fileUpload" className={cn('uploadButton')}>
            파일 업로드
            {/* 아이콘 영역 */}
            <div className={cn('icon')}>
              <img src={getUrlPath('upload_icon.png')} alt="upload_icon" />
            </div>
          </label>
        </div>
      </div>

      <div className={cn('agreement-container')}>
        <div className={cn('agreement-title')}>약관동의</div>

        <div className={cn('agreement-item')}>
          <div className={cn('checkbox-wrapper')}>
            <input
              type="checkbox"
              className={cn('checkbox')}
              checked={isPrivacyConsentChecked}
              onChange={handlePrivacyConsentChange}
            />
            <label className={cn('checkbox-label')}>
              [필수] 개인정보수집 동의
            </label>
          </div>
          <div
            className={cn('arrow-text-wrapper')}
            onClick={handlePrivacyConsentClose}
          >
            <div className={cn('arrow-text')}>내용보기</div>
            <img
              className={cn('arrow-text-icon')}
              src={getUrlPath('right_small.png')}
              alt="right_small"
            />
          </div>
        </div>

        <div className={cn('agreement-item')}>
          <div className={cn('checkbox-wrapper')}>
            <input
              type="checkbox"
              className={cn('checkbox')}
              checked={isSensitiveInfoConsentChecked}
              onChange={handleSensitiveInfoConsentChange}
            />
            <label className={cn('checkbox-label')}>
              [필수] 민감정보 수집 및 이용 동의
            </label>
          </div>
          <div
            className={cn('arrow-text-wrapper')}
            onClick={handleSensitiveInfoConsentClose}
          >
            <div className={cn('arrow-text')}>내용보기</div>
            <img
              className={cn('arrow-text-icon')}
              src={getUrlPath('right_small.png')}
              alt="right_small"
            />
          </div>
        </div>

        <div className={cn('agreement-item')}>
          <div className={cn('checkbox-wrapper')}>
            <input
              type="checkbox"
              className={cn('checkbox')}
              checked={isRecordConsentChecked}
              onChange={handleRecordConsentChange}
            />
            <label className={cn('checkbox-label')}>
              [필수] 본인의 생활기록부 내용이 맞으신가요? 타인의 생활기록부를
              무단으로 이용하는 것은 금지되어있으며, 이로 인해 발생하는 문제의
              모든 책임은 업로드한 회원에게 있습니다.
            </label>
          </div>
        </div>
      </div>
      <div className={cn('buttons-container')}>
        <button
          className={cn('button', { cancel: true })}
          onClick={handleGoToBack}
        >
          취소
        </button>
        <button
          className={cn('button', { confirm: true })}
          onClick={handleFileUpload}
        >
          완료
        </button>
      </div>

      <Popup
        isOpen={isPrivacyConsentOpen}
        onClose={handlePrivacyConsentClose}
        backgroundClickClose={false}
        isCloseButton={false}
        width="w-[335px] md:w-[453px] lg:w-[800px]"
      >
        <div className={cn('popupContainer')}>
          <div className={cn('popupTitle')}>
            [필수] 개인정보 수집 및 이용 동의
          </div>
          <div className={cn('popupText')}>
            수시탐탐은 &quot;생기부 분석&quot; 서비스를 위하여 생활기록부에
            포함되는 개인정보의 수집 및 이용목적, 보유기간, 동의 거부권 등에
            관한 사항을 안내드리오니, 자세히 읽은 후 동의하여 주시기 바랍니다.
          </div>
          <div className={cn('popupList')}>
            <div className={cn('listTitle')}>수집 및 이용 목적</div>
            <div className={cn('listItem')}>생기부 분석</div>

            <div className={cn('listTitle')}>수집항목</div>
            <div className={cn('listItem')}>
              [필수] 학생 성명, 학교, 반, 번호
            </div>
            <div className={cn('listItem')}>
              주민등록번호, 주소, 성별, 가족사항, 담당성명은 수집되지 않습니다.
            </div>

            <div className={cn('listTitle')}>보유기간</div>
            <div className={cn('listItem')}>
              회원탈퇴 시, 삭제 요청 시, 법정 의무 보유 기간
            </div>

            <div className={cn('popupText')}>
              회원은 위 개인정보의 수집에 동의하지 않을 수 있습니다. 다만 생기부
              분석 서비스는 이용하실 수 없습니다. 위 개인정보 수집 및 이용에
              동의하지 않는 경우 다른 서비스의 이용에는 지장이 없습니다.
            </div>
          </div>
        </div>
        <div className={cn('popupFooter')} onClick={handlePrivacyConsentClose}>
          <button>확인</button>
        </div>
      </Popup>
      <Popup
        isOpen={isSensitiveInfoConsentOpen}
        onClose={handleSensitiveInfoConsentClose}
        backgroundClickClose={false}
        isCloseButton={false}
        width="w-[335px] md:w-[453px] lg:w-[800px]"
      >
        <div className={cn('popupContainer')}>
          <div className={cn('popupTitle')}>
            [필수] 민감정보 수집 및 이용 동의
          </div>
          <div className={cn('popupText')}>
            수시탐탐은 &quot;생기부 분석&quot; 서비스를 위하여 생활기록부에
            포함되는 민감정보의 수집 및 이용목적, 보유기간, 동의 거부권 및 동의
            거부 시 불이익에 관한 사항을 안내드리오니 자세히 읽은 후 동의하여
            주시기 바랍니다.
          </div>
          <div className={cn('popupList')}>
            <div className={cn('listTitle')}>수집 및 이용 목적</div>
            <div className={cn('listItem')}>생기부 분석</div>

            <div className={cn('listTitle')}>수집항목</div>
            <div className={cn('listItem')}>
              [필수] 학적사항 (종교, 교외, 입학, 졸업, 전학 등의 기록)
            </div>
            <div className={cn('listItem')}>
              출결상황 (결석, 지각, 조퇴, 결과 등의 기록)
            </div>
            <div className={cn('listItem')}>
              수상경력 (수상명, 순위, 수상일자, 수여기관, 참가대회 및 인원)
            </div>
            <div className={cn('listItem')}>
              자격증 및 인증 취득사항 (자격정보, 내용, 취득일자, 발급기관 등)
            </div>
            <div className={cn('listItem')}>
              진로희망사항 (학년, 진로희망, 진로희망 사유)
            </div>
            <div className={cn('listItem')}>
              창의적 체험활동 사항 (자율/동아리/봉사/진로활동 시간, 특기사항 등)
            </div>
            <div className={cn('listItem')}>
              교과학습발달상황 (학업이수 과목, 과목명, 단위수, 성취도, 과목별
              점수, 과목평균, 표준편차, 석차등급, 세부능력 및 특기사항)
            </div>
            <div className={cn('listItem')}>
              독서활동상황 (관련 기록, 독서 시기, 도서명, 저자)
            </div>
            <div className={cn('listItem')}>행동특성 및 종합의견</div>

            <div className={cn('listTitle')}>보유기간</div>
            <div className={cn('listItem')}>
              회원탈퇴 시, 삭제 요청 시, 법정 의무 보유 기간
            </div>

            <div className={cn('popupText')}>
              회원은 위 민감정보의 수집에 동의하지 않을 수 있습니다. 다만 생기부
              분석 서비스는 이용하실 수 없습니다. 민감정보 수집 및 이용에
              동의하지 않는 경우 다른 서비스의 이용에는 지장이 없습니다.
            </div>
          </div>
        </div>
        <div
          className={cn('popupFooter')}
          onClick={handleSensitiveInfoConsentClose}
        >
          <button>확인</button>
        </div>
      </Popup>
    </div>
  );
};

export default Upload;
